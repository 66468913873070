import { getToken, getUser } from "../Utils/Common";
import { getAPICall, postAPICall } from "./axiosMethodCalls";

const user = getUser();
const token = getToken();

export const getAllProjects = async () => {
  try {
    const response = await getAPICall(
      process.env.REACT_APP_LINK + "projects/get_all_project",
      {
        requester: user,
        token: token,
      }
    );
    return { data: response.data };
  } catch (error) {
    return { error: error.response };
  }
};
export const getSingleBranch = async (id) => {
  try {
    const response = await getAPICall(
      process.env.REACT_APP_LINK + "projects/get_project",
      {
        requester: user,
        token: token,
        project_id: id,
      }
    );
    return { data: response.data };
  } catch (error) {
    return { error: error.response };
  }
};

export const searchProject = async (name) => {
  try {
    const response = await getAPICall(
      process.env.REACT_APP_LINK + "projects/search",
      {
        requester: getUser(),
        token: getToken(),
        name: name,
      }
    );
    return { data: response.data };
  } catch (error) {
    return { error: error.response };
  }
};

export const createBranch = async (data) => {
  try {
    const response = await postAPICall(
      process.env.REACT_APP_LINK + "projects/create",
      {
        requester: getUser(),
        token: getToken(),
        name: data.name,
        company: data.company,
        address: data.address,
        phone_no: data.phone_no,
        tin_no: data.tin_no,
        contact_person: data.contact_person,
      }
    );
    return { data: response.data };
  } catch (error) {
    return { error: error.response };
  }
};

export const updateBranch = async (data) => {
  try {
    const response = await postAPICall(
      process.env.REACT_APP_LINK + "projects/update",
      {
        requester: getUser(),
        token: getToken(),
        project_id: data.id,
        name: data.name,
        company: data.company,
        address: data.address,
        phone_no: data.phone_no,
        tin_no: data.tin_no,
        contact_person: data.contact_person,
      }
    );
    return { data: response.data };
  } catch (error) {
    return { error: error.response };
  }
};

export const deleteBranch = async (id) => {
  try {
    const response = await getAPICall(
      process.env.REACT_APP_LINK + "projects/delete",
      {
        requester: getUser(),
        token: getToken(),
        project_id: id,
      }
    );
    return { data: response.data };
  } catch (error) {
    return { error: error.response };
  }
};

export const searchProjectInv = async (name) => {
  try {
    const response = await getAPICall(
      process.env.REACT_APP_LINK + "project_invoices/search",
      {
        requester: getUser(),
        token: getToken(),
        project_id: name,
      }
    );
    return { data: response.data };
  } catch (error) {
    return { error: error.response };
  }
};