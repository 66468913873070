import toast from "react-hot-toast";
import { formatDate, getToken, getUser, toastStyle } from "../Utils/Common";
import { getAPICall, postAPICall, postAPICall2 } from "./axiosMethodCalls";
const user = getUser();
const token = getToken();
//GET
export const getAllExpenseType = async () => {
  try {
    const response = await getAPICall(
      process.env.REACT_APP_LINK + "expense_types/get_all_expense_type",
      {
        requester: user,
        token: token,
      }
    );
    return response.data;
  } catch (error) {
    return { error: error.response };
  }
};

export const getExpenseType = async (id) => {
  try {
    const response = await getAPICall(
      process.env.REACT_APP_LINK + "expense_types/get_expense_types",
      {
        requester: user,
        token: token,
        expense_type_id: id,
      }
    );
    return { data: response.data };
  } catch (error) {
    return { error: error };
  }
};

export const createExpenseType = async (info) => {
  try {
    const response = await postAPICall(
      process.env.REACT_APP_LINK + "expense_types/create",
      {
        requester: user,
        token: token,
        name: info.name,
        description: info.description,
      }
    );
    return { data: response.data };
  } catch (error) {
    return { error: error };
  }
};

export const editExpenseType = async (expensetypeData) => {
  try {
    const response = await postAPICall(
      process.env.REACT_APP_LINK + "expense_types/update",
      {
        requester: user,
        token: token,
        expense_type_id: expensetypeData.id,
        name: expensetypeData.name,
        description: expensetypeData.description,
      }
    );
    return { data: response.data };
  } catch (error) {
    if (error.response.status === 401) {
      toast.error("Unauthorized access: another device is using this account", {
        style: toastStyle(),
      });
      //   removeSession();
    }
    return { error: error.response };
  }
};

export const deleteExpenseType = async (id) => {
  try {
    const response = await postAPICall(
      process.env.REACT_APP_LINK + "expense_types/delete",
      {
        requester: user,
        token: token,
        expense_type_id: id,
      }
    );
    return { data: response };
  } catch (error) {
    if (error.response.status === 401) {
      toast.error("Unauthorized access: another device is using this account", {
        style: toastStyle(),
      });
      //   removeSession();
    }
    return { error: error.response };
  }
};

export const searchExpenseType = async (name, description) => {
  try {
    const response = await getAPICall(
      process.env.REACT_APP_LINK + "expense_types/search",
      {
        requester: getUser(),
        token: getToken(),
        name: name,
        description: description,
        // address: search.address,
        // email: search.email,
        // contact_person: search.contact_person,
        // phone_no: search.phone_no,
        // tin_no: search.tin_no,
        // bir_no: search.bir_no,
      }
    );
    return { response: response.data };
  } catch (error) {
    return { error: error.response };
  }
};
