import moment from "moment";
import { Fragment } from "react";
import React, { useState, useCallback, useRef} from "react";
import { Col, Container, Row, Table, Form, Button, Modal, Tabs, Tab } from "react-bootstrap";
import toast from "react-hot-toast";
import { useNavigate, useLocation } from "react-router-dom";
import cleanLogo from "../../Assets/Images/Login/logo.png";
import Navbar from "../../Components/Navbar/Navbar";
import {
  numberFormat, toastStyle
} from "../../Helpers/Utils/Common";
import { createSubscriptionBilling, generateSubscriptionBilling, getSubscriptionBillings, updateSubscriptionBilling } from "../../Helpers/apiCalls/Subscription/SubscriptionBilling";

export default function GenerateSubscriptionBilling () {
  const navigate = useNavigate();
  const location = useLocation();
  const formOrigin = location.state.formOrigin;
  const formPurpose = location.state.formPurpose;
  const passedBillingId = location.state.subscription_id;
  const passedProjectId = location.state.project_id;
  const billingDate = location.state.billingDate;
  const [inactive, setInactive] = useState(true);
  const [confirmAdd, setConfirmAdd] = useState(false);
  const [grandTotal, setGrandTotal] = useState(0);
  const [distributorDetails, setDistributorDetails] = useState({});
  const [formErrors, setFormErrors] = useState({});
  const [recurringCosts, setRecurringCosts] = useState([]);
  const [clientDetails, setClientDetails] = useState([])

  const handleUpdate = (e, projectIndex) => {
    const {name, value} = e.target;
    setRecurringCosts((prevDetails) => {
      const updatedDetails = [...prevDetails];
      updatedDetails[projectIndex][name] = value;
      return updatedDetails;
    });
  };

  
  async function handleSubmitBilling () {
    const errors = {}
    
    const finalProjectDetails = {
      project_id: distributorDetails.project_id,
      grand_total: 0,
      billing_date: moment(billingDate).format("YYYY-MM-DD"),
      project_recurring_cost_ids: [],
      project_ids: [],
      subscription_billing_entry_dates: [],
      due_dates: [],
      amounts: [],
    }
      recurringCosts.forEach((project, projectIndex) => {
        if (!project.billing_date) {
          if (!errors[projectIndex]) {
            errors[projectIndex] = {};
          }
          errors[projectIndex] = {
            ...errors[projectIndex],
            billing_date: 'Billing date is required',
          };
        } else {
          finalProjectDetails.project_recurring_cost_ids.push(project.id);
          finalProjectDetails.project_ids.push(project.project_id);
          finalProjectDetails.subscription_billing_entry_dates.push(project.billing_date);
          finalProjectDetails.amounts.push(project.price);
          finalProjectDetails.grand_total += Number(project.price);
        }

        if (!project.due_date) {
          if (!errors[projectIndex]) {
            errors[projectIndex] = {};
          }
          errors[projectIndex] = {
            ...errors[projectIndex],
            due_date: 'Due date is required',
          };
        } else {
          finalProjectDetails.due_dates.push(project.due_date);
        }

        // if (!project.paid_amount) {
        //   if (!errors[projectIndex]) {
        //     errors[projectIndex] = {};
        //   }
        //   errors[projectIndex] = {
        //     ...errors[projectIndex],
        //     paid_amount: 'Project paid_amount is required',
        //   };
        // } else {
        //   finalProjectDetails.grand_total += Number(project.paid_amount);
        //   finalProjectDetails.amounts.push(project.paid_amount);
        // }
      });

    setFormErrors(errors);

    if (Object.keys(errors).length===0) {
      let response = ""

      if (formPurpose === "edit") {
        response = await updateSubscriptionBilling({subscription_billing_id: passedBillingId, ...finalProjectDetails});
      } else {
        response = await createSubscriptionBilling(finalProjectDetails);
      }
      if (response.data) {
        toast.success(response.data.response, {style: toastStyle()})
        setTimeout(() => navigate("/subscription_billings"), 1000);
      } else {
        toast.error(response.error.data.messages.response, {style: toastStyle()})
      }
    }
  }

  async function getRecurringCosts () {
    const projectBilling = await generateSubscriptionBilling({project_id: passedProjectId, billing_date: moment(billingDate).format("YYYY-MM-DD")});
    if (projectBilling.data) {
      const projectData = projectBilling.data.project[0];
      projectData.project_id = projectData.id;
      setDistributorDetails(projectData);
      if (projectBilling.data.subscription_billing_entries) {
        var tempGrandTotal = 0
        const tempCosts = projectBilling.data.subscription_billing_entries.map((data) => {
          tempGrandTotal += Number(data.price);
          var info = data;
          info.billing_date = moment(billingDate).format("YYYY-MM-DD");
          return info;
        });
        setGrandTotal(tempGrandTotal);
        setRecurringCosts(tempCosts);
      }
    }
  }

  async function getBillingDetails () {
    const response = await getSubscriptionBillings({billing_id: passedBillingId});
    if (response.data) {
      const billingData = response.data.data[0];
      setDistributorDetails(billingData);
      if (response.data.data[0].subscription_billing_entries) {
        var tempGrandTotal = 0
        const tempCosts = response.data.data[0].subscription_billing_entries.map((data) => {
          var info = data;
          tempGrandTotal += Number(data.amount);
          info.billing_date = moment(data.subscription_billing_entry_date).format("YYYY-MM-DD");
          info.due_date = data.due_date;
          info.price = data.project_recurring_cost_price;
          return info;
        });
        setGrandTotal(tempGrandTotal);
        setRecurringCosts(tempCosts);
      }
    }
  }

  const calculateGrandTotal = (details) => {
      const projectTotal = details.reduce((projectSum, project) => {
        const tempCost = typeof project.paid_amount !== 'undefined' ? parseFloat(project.paid_amount) : 0;
        return projectSum + tempCost;
      }, 0);
  
    setGrandTotal(projectTotal)
  }


  React.useEffect(() => {
    if (formPurpose==="add") {
      getRecurringCosts();
    } else {
      getBillingDetails();
    }
  }, []);

  const RecurringCostsTable = () => {
    return (
      <div className="edit-purchased-items mb-5">
        <Table bordered>
          <thead>
            <tr>
              <th>Description</th>
              <th>Period</th>
              <th>Price</th>
              <th>Billing Date</th>
              <th>Deadline</th>
              {/* <th className="text-right">Paid Amount</th> */}
            </tr>
          </thead>
          <tbody>
            {recurringCosts.map((project, projectIndex) => (
              <React.Fragment key={projectIndex}>
                <tr key={projectIndex}>
                      <td className="w-30">
                        <Form.Control value={project.description||project.project_recurring_cost_description} disabled/>
                      </td>
                    <td>
                      <Form.Control value={project.period||project.project_recurring_cost_period} disabled/>
                    </td>
                    <td>
                      <Form.Control
                        value={project.price}
                        disabled
                      />
                    </td>
                    <td>
                      <Form.Control
                        value={project.billing_date}
                        type="date"
                        name="billing_date"
                        onChange={(e) => handleUpdate(e, projectIndex)}
                      />
                      {formErrors[projectIndex] && formErrors[projectIndex].billing_date && (
                        <div className="validity-error callout bottom">{formErrors[projectIndex].billing_date}</div>
                      )}
                    </td>
                    <td>
                      <Form.Control
                        value={project.due_date}
                        type="date"
                        name="due_date"
                        onChange={(e) => handleUpdate(e, projectIndex)}
                      />
                      {formErrors[projectIndex] && formErrors[projectIndex].due_date && (
                        <div className="validity-error callout bottom">{formErrors[projectIndex].due_date}</div>
                      )}
                    </td>
                    {/* <td>
                      <Form.Control
                        value={project.paid_amount}
                        type="number"
                        className="text-end"
                        name="paid_amount"
                        onChange={(e) => handleUpdate(e, projectIndex)}
                      />
                      {formErrors[projectIndex] && formErrors[projectIndex].price && (
                        <div className="validity-error callout bottom">{formErrors[projectIndex].price}</div>
                      )}
                    </td> */}
                  </tr>
              </React.Fragment>
            ))}
          </tbody>
        </Table>
        <Row className="mt-4">
          <Col xs={6}></Col>
          <Col xs={3} className="color-gray grand-total-text text-center">Grand Total</Col>
          <Col xs={1} className="grand-total-text">PHP</Col>
          <Col xs={2} className="text-end grand-total-text">
            {grandTotal
              ? numberFormat(grandTotal)
              : "0.00"}
          </Col>
        </Row>
      </div>
    );
  };
  
  return (
    <div>
      <div className="page">
        <Navbar
          onCollapse={(inactive) => {
            setInactive(inactive);
          }}
          active={"SALES"}
        />
      </div>
      <div className={`container ${inactive ? "inactive" : "active"}`}>
        <div className="print-container px-5 py-2" id="printablediv">
          <div className="print-grand-total my-3 text-end print-header d-flex flex-column">
            
            {
              formPurpose==="add" && (
                <span>PROJECT NO. {passedProjectId}</span>
              )
            }
            {
              formPurpose==="edit" && (
                <span>SUBSCRIPTION BILLING NO. {passedBillingId}</span>
              )
            }
            <span className="text-uppercase">
              {moment().format("MMMM DD, YYYY")}
            </span>
          </div>
          <div className="d-flex justify-content-center py-1">
            <img src={cleanLogo} className="print-logo" />
          </div>

          {/* content */}
          <div className="print-body mt-5">
          <Fragment>
            <Row className="mt-4 mb-2">
                <Col>
                    <span htmlFor="customer_id" className="edit-label">Poject Name</span>
                    <Form.Control
                      value={distributorDetails.name||distributorDetails.project_name}
                      name="name"
                      disabled
                    />
                </Col>

              <Col>
              <span htmlFor="customer_id" className="edit-label">Address</span>
                <Form.Control
                  type="text"
                  name="address"
                  value={distributorDetails.address||distributorDetails.project_address}
                  disabled
                />
              </Col>
            </Row>

            <Row className="mt-3">
            <Col>
              <span htmlFor="customer_id" className="edit-label">CUSTOMER </span>
                <Form.Control
                  type="text"
                  name="contact_person"
                  value={distributorDetails.customer_name||distributorDetails.project_customer_name}
                  disabled
                />
              </Col>
              <Col>
              <span htmlFor="customer_id" className="edit-label">CONTACT PERSON </span>
                <Form.Control
                  type="text"
                  name="contact_person"
                  value={distributorDetails.contact_person||distributorDetails.project_contact_person}
                  disabled
                />
              </Col>
              <Col>
              <span htmlFor="customer_id" className="edit-label">CONTACT NUMBER</span>
                <Form.Control
                  type="text"
                  name="contact_no"
                  value={distributorDetails.contact_no||distributorDetails.project_contact_number}
                  disabled
                />
              </Col>
            </Row>
          </Fragment>

          <Row className="mt-4 pt-3">
            <span className="edit-label mb-2">RECURRING COSTS</span>
            <RecurringCostsTable/>
          </Row>
          </div>
        </div>
        

        {/* footer */}
        <Row className="d-flex justify-content-end my-4 pb-5 d-flex-responsive">
          <Col xs={3}></Col>
          <Col xs={2}>
            <button
              className="button-secondary w-100"
              onClick={() => navigate(-1)}
            >
              Close
          </button>
          </Col>

          <Col xs={2}>
            <button className="button-primary w-100" onClick={()=>setConfirmAdd(true)}>
              Confirm Billing
            </button>
          </Col>
        </Row>

        <Modal show={confirmAdd} onHide={()=>setConfirmAdd(false)}>
          <Modal.Body className="p-4">
            Are you sure you want to submit this billing statement? This action cannot be undone.
            <hr></hr>
            <div className="col-sm-12 mt-1 d-flex justify-content-end">
              <button className="button-secondary me-3 py-0" onClick={() => setConfirmAdd(false)}>
                Cancel
              </button>
              <button className="button-primary py-0" onClick={handleSubmitBilling}>
                Confirm
              </button>
            </div>
          </Modal.Body>
        </Modal>
      </div>
    </div>
  );
}