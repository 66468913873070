import React, { useState } from "react";
import { Col, Row } from "react-bootstrap";
import { useNavigate, useParams } from "react-router-dom";
import toast from "react-hot-toast";
import "react-autocomplete-input/dist/bundle.css";
import "react-bootstrap-typeahead/css/Typeahead.css";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import NoDataImg from "../../Assets/Images/no-data-dog.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import {
  numberFormat,
  refreshPage,
  toastStyle,
} from "../../Helpers/Utils/Common";
import Navbar from "../../Components/Navbar/Navbar";
import "../Purchases/PurchaseOrders/PurchaseOrders.css";
import "./ProjectExpenses.css";
import { Fragment } from "react";
import { useEffect } from "react";
import {
  getProjectExpense,
  updateStatusProjectExpense,
} from "./../../Helpers/apiCalls/ProjectInvoice/ProjectExpenseApi";
import moment from "moment";



function ViewProjectExpenses() {
  let navigate = useNavigate();
  const [inactive, setInactive] = useState(true);

  const { id, status } = useParams();

  // FRANCHISEE INVOICE DETAILS HANDLER
  const [addDetails, setAddDetails] = useState({});

  async function fetchProjectExpense() {
    const response = await getProjectExpense(id);
    if (response.data) {
      var data = response.data.data[0];
      setAddDetails(data);
    } else {
      toast.error(response.error.data.messages.error, {
        style: toastStyle(),
      });
    }
  }

  function Print() {
    let printContents = document.getElementById("printablediv").innerHTML;
    let originalContents = document.body.innerHTML;
    document.body.innerHTML = printContents;
    window.print(printContents);
    document.body.innerHTML = originalContents;
    // refreshPage();
  }

  async function handlePrint() {
    toast.loading("Printing project invoice...", { style: toastStyle() });
    setTimeout(() => {
      toast.dismiss();
      Print();
    }, 1000);
  }

  async function updateStatus(id, status) {
    const response = await updateStatusProjectExpense(id, status);
    if (response.data) {
        toast.success("Successfully Updated Project Invoice Status")
        setTimeout(() => navigate(-1), refreshPage(), 1000);
    } else {
      toast.error("Error Updating Project Invoice", {
        style: toastStyle(),
      });
    }
  }

  useEffect(() => {
    fetchProjectExpense();
  }, []);

  return (
    <div>
      <div className="page">
        <Navbar
          onCollapse={(inactive) => {
            setInactive(inactive);
          }}
          active={"SALES"}
        />
      </div>

      <div className={`container ${inactive ? "inactive" : "active"}`}>
        {/* header */}
        <div className="d-flex justify-content-between align-items-center my-3 pb-4">
          <h1 className="page-title mb-0">VIEW PROJECT EXPENSE</h1>
        </div>

        {/* content */}
        <div className="edit-form">
          {/* FRANCHISEE SALES INVOICE DETAILS */}
          <div id="printablediv">
            <Fragment>
              <Row className="review-container py-3">
                <Row>
                  <Col xs={4}>
                    <span className="review-label">Project Name</span>
                  </Col>
                  <Col xs={3}>
                    <span className="review-label">Project Price</span>
                  </Col>
                  <Col xs={3}>
                    <span className="review-label">Expense Type</span>
                  </Col>
                  <Col xs={2}>
                    <span className="review-label">Expense Date</span>
                  </Col>
                </Row>
                <Row>
                  <Col xs={4}>
                    <span className="review-data">
                      {addDetails.project_name}
                    </span>
                  </Col>
                  <Col xs={3}>
                    <span className="review-data">
                      {addDetails.project_price
                        ? numberFormat(addDetails.project_price)
                        : ""}
                    </span>
                  </Col>
                  <Col xs={3}>
                    <span className="review-data">
                      {addDetails.expense_type_name}
                    </span>
                  </Col>
                  <Col xs={2}>
                    <span className="review-data">
                      {addDetails.project_expense_date?moment(addDetails.project_expense_date).format('MMM DD, YYYY'):""}
                    </span>
                  </Col>
                </Row>
              </Row>
              <Row className="review-container py-3">
                <Row>
                  <Col xs={4}>
                    <span className="review-label">Supplier</span>
                  </Col>
                  <Col xs={8}>
                    <span className="review-label">Remarks</span>
                  </Col>
                  {/* <Col xs={4}>
                    <span className="review-label">Project Net Sales</span>
                  </Col> */}
                  
                </Row>
                <Row>
                  <Col xs={4}>
                    <span className="review-data">
                      {addDetails.supplier_name}
                    </span>
                  </Col>
                  <Col xs={8}>
                    <span className="review-data">{addDetails.remarks}</span>
                  </Col>
                  {/* <Col xs={4}>
                    <span className="review-data">
                      {addDetails.project_net_sales}
                    </span>
                  </Col> */}
                  
                </Row>
              </Row>
            </Fragment>
            <Row className="mt-4 pt-3">
              <Col xs={12}></Col>
            </Row>
            <Row className="align-right pt-3">
              <Col xs={2} className="text-end">
                <span className="edit-label color-gray">Amount</span>
              </Col>
              <Col xs={1} className="text-end">
                <span className="edit-label align-middle">PHP</span>
              </Col>
              <Col xs={3} className="text-end">
                <span className="edit-label color-gray">
                  {numberFormat(addDetails.amount)}
                </span>
              </Col>
            </Row>
            <Row className="align-right pt-3">
              <Col xs={2} className="text-end">
                <span className="edit-label color-gray">Other fees</span>
              </Col>
              <Col xs={1} className="text-end">
                <span className="edit-label align-middle">PHP</span>
              </Col>
              <Col xs={3} className="text-end">
                <span className="edit-label color-gray">
                  {numberFormat(addDetails.other_fees)}
                </span>
              </Col>
            </Row>
            <Row className="align-right py-5">
              <Col xs={2} className="text-end">
                <span className="edit-label color-gray grand-total-text">
                  Grand Total
                </span>
              </Col>
              <Col xs={1} className="text-end">
                <span className="edit-label align-middle grand-total-text">
                  PHP
                </span>
              </Col>
              <Col xs={3} className="text-end">
                <span className="edit-label align-middle grand-total-text">
                  {numberFormat(addDetails.grand_total)}
                </span>
              </Col>
            </Row>
          </div>
          <Row className="py-3">
            <span className="edit-label color-gray">ATTACHMENT/S</span>
            <Col className="print-table">
              {addDetails?.attachment?.length > 0 ? (
                addDetails?.attachment.map((data) => {
                    return (
                        <Row className="px-5 py-2"><FontAwesomeIcon icon="fa-regular fa-file" /><a href={data.base_64} download={data.name} className="edit-label">{data.name}</a></Row>
                    )
                })
                // <>
                //   <Carousel showArrows={true}>
                //     {addDetails?.attachment.map((data) => {
                //       if (data.base_64 && data.base_64.startsWith("data:image")) {
                //         if (data.base_64.startsWith("data:image")) {
                //             return (
                //                 <>
                //                   <div
                //                       className="mt-5 mb-2"
                //                       style={{ textAlignLast: "center" }}
                //                   >
                //                       <img
                //                           src={data.base_64}
                //                           width={100}
                //                           height={100}
                //                       />
                //                   </div>
                //                 </>
                //               );
                //         } else {
                            
                //         }
                //       } else {
                //         return (
                //           <>
                //             <div className="no-data-cont">
                //               <div
                //                 className="mt-5 mb-2"
                //                 style={{ textAlignLast: "center" }}
                //                 alt="no data found"
                //               >
                //                 <img src={NoDataImg} width={100} height={100} />
                //               </div>
                //               <span>Uh Oh! No data found.</span>
                //             </div>
                //           </>
                //         );
                //       }
                //     })}
                //   </Carousel>
                // </>
              ) : (
                <div className="no-data-cont">
                  <div
                    className="mt-5 mb-2"
                    style={{ textAlignLast: "center" }}
                    alt="no data found"
                  >
                    <img src={NoDataImg} width={100} height={100} />
                  </div>
                  <span>Uh Oh! No data found.</span>
                </div>
              )}
            </Col>
          </Row>

          {/* FOOTER: CANCEL & SUBMIT BUTTONS */}
          {status === "pending" ? (
            <div className="d-flex justify-content-end pt-5 pb-3">
              <button
                type="button"
                className="button-secondary green-btn me-3"
                onClick={() => updateStatus(id, "approved")}
              >
                Approve
              </button>
              <button
                type="button"
                className="button-secondary red-btn me-3"
                onClick={() => updateStatus(id, "disapproved")}
              >
                Disapprove
              </button>
              <button
                type="button"
                className="button-secondary me-3"
                onClick={handlePrint}
              >
                Print
              </button>
              <button
                type="button"
                className="button-secondary me-3"
                onClick={() => navigate(-1)}
              >
                Close
              </button>
            </div>
          ) : (
            <div className="d-flex justify-content-end pt-5 pb-3">
              <button
                type="button"
                className="button-secondary me-3"
                onClick={() => navigate(-1)}
              >
                Close
              </button>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

ViewProjectExpenses.defaultProps = {
  add: false,
  edit: false,
};

export default ViewProjectExpenses;
