import moment from "moment";
import { Fragment } from "react";
import React, { useState, useCallback, useRef} from "react";
import { Col, Container, Row, Table, Form, Button, Modal, Tabs, Tab } from "react-bootstrap";
import toast from "react-hot-toast";
import { useNavigate, useLocation } from "react-router-dom";
import cleanLogo from "../../Assets/Images/Login/logo.png";
import Navbar from "../../Components/Navbar/Navbar";
import {
  numberFormat, toastStyle
} from "../../Helpers/Utils/Common";
import { generateDistributorBilling, generateMonthlyBilling, getOneDistributor, getOneDistributorBilling, updateDistributorBilling } from "../../Helpers/apiCalls/Distributor/DistributorApi";


export default function DistributorGenerateBilling () {
  const navigate = useNavigate();
  const location = useLocation();
  const formOrigin = location.state.formOrigin;
  const formPurpose = location.state.formPurpose;
  const passedBillingId = location.state.billing_id;
  const passedDistributorId = location.state.distributor_id;
  const billingDate = location.state.billingDate;
  const [inactive, setInactive] = useState(true);
  const [confirmAdd, setConfirmAdd] = useState(false);
  const [grandTotal, setGrandTotal] = useState(0);
  const [distributorDetails, setDistributorDetails] = useState({});

  const [clientDetails, setClientDetails] = useState([])

  const handleUpdate = (e, customerIndex, projectIndex) => {
    const {name, value} = e.target;
    setClientDetails((prevDetails) => {
      const updatedDetails = [...prevDetails];
      updatedDetails[customerIndex].projects[projectIndex][name] = value;
      calculateGrandTotal(updatedDetails);
      return updatedDetails;
    });
  };

  const [formErrors, setFormErrors] = useState({})

  async function handleSubmitBilling () {
    const errors = {}
    
    const finalProjectDetails = {
      distributor_id: distributorDetails.id,
      grand_total: 0,
      billing_date: billingDate,
      distributor_client_ids: [],
      project_ids: [],
      distributor_billing_entry_dates: [],
      due_dates: [],
      amounts: [],
    }

    clientDetails.forEach((client, customerIndex) => {
      client.projects.forEach((project, projectIndex) => {
        if (!project.billing_date) {
          if (!errors[customerIndex]) {
            errors[customerIndex] = {};
          }
          errors[customerIndex][projectIndex] = {
            ...errors[customerIndex][projectIndex],
            billing_date: 'Billing date is required',
          };
        } else {
          finalProjectDetails.distributor_client_ids.push(client.id);
          finalProjectDetails.project_ids.push(project.project_id);
          finalProjectDetails.distributor_billing_entry_dates.push(project.billing_date);
          finalProjectDetails.due_dates.push(project.due_date);
        }

        if (!project.cost) {
          if (!errors[customerIndex]) {
            errors[customerIndex] = {};
          }
          errors[customerIndex][projectIndex] = {
            ...errors[customerIndex][projectIndex],
            cost: 'Project cost is required',
          };
        } else {
          finalProjectDetails.grand_total += Number(project.cost);
          finalProjectDetails.amounts.push(project.cost);
        }
      });
    });

    setFormErrors(errors);

    if (Object.keys(errors).length===0) {
      let response = ""

      if (formPurpose === "edit") {
        response = await updateDistributorBilling({distributor_billing_id: passedBillingId, ...finalProjectDetails});
      } else {
        response = await generateDistributorBilling(finalProjectDetails);
      }
      if (response.data) {
        toast.success(response.data.response, {style: toastStyle()})
        setTimeout(() => navigate("/distributor_billings"), 1000);
      } else {
        toast.error(response.error.data.messages.response, {style: toastStyle()})
      }
    }
  }

  async function getDistributorDetails () {
    const billingResponse = await generateMonthlyBilling({distributor_id: passedDistributorId, billing_date: billingDate})
    setDistributorDetails(billingResponse.data.distributor);
    const inputArray = billingResponse.data.distributor_billing_entries;
    const mergedMap = new Map();
    inputArray.forEach(item => {
      const customerID = item.customer_id;
      const project = {
        project_id: item.project_id,
        project_name: item.project_name,
        project_date: item.distributor_client_date,
        billing_date: moment(billingDate).format("YYYY-MM-DD"),
        due_date: item.due_date,
        cost:item.grand_total
      };
    
      if (mergedMap.has(customerID)) {
        mergedMap.get(customerID).projects.push(project);
      } else {
        mergedMap.set(customerID, {
          id: item.id,
          customer_id: customerID,
          customer_name: item.customer_name,
          projects: [project],
        });
      }
    });
    const mergedArray = Array.from(mergedMap.values());
    setClientDetails(mergedArray);
    // setGrandTotal(mergedArray.map((data)=>{data.projects.map((info)=> info.cost).reduce((a, b)=> a + b,0).toFixed(2)}))
    // {data.((info)=>
    //   parseFloat(data.cost)}).reduce((a, b) => a + b, 0).toFixed(2))
    // setGrandTotalDaily(
    //     response.data.message.datas
    //       .map((data) => parseFloat(data.total_amount))
    //       .reduce((a, b) => a + b, 0)
    //       .toFixed(2)
    //   )
  }

  async function getBillingDetails () {
    const response = await getOneDistributorBilling({distributor_billing_id: passedBillingId});
    if (response.data) {
      const billingDeets = response.data.data[0];
      const res = response.data.data.map((row) => {
        var info = row;
        row.distributor.map((dist) => {
          info.distributor_name = dist.name;
          info.contact_person = dist.contact_person;
          info.contact_no = dist.contact_no;
          info.address = dist.address;
        })
        return info;
      })
      setGrandTotal(res[0].grand_total);
      setDistributorDetails(res[0]);

      // setDistributorDetails(billingDeets.distributor[0]);
      const inputArray = billingDeets.distributor_billing_entries;
      const mergedMap = new Map();

      inputArray.forEach(item => {
        const customerID = item.customer_id;
        const project = {
          project_id: item.project_id,
          project_name: item.project_name,
          project_date: item.project_date,
          billing_date: item.distributor_billing_entry_date,
          due_date: item.due_date,
          cost: item.amount,
        };
      
        if (mergedMap.has(customerID)) {
          mergedMap.get(customerID).projects.push(project);
        } else {
          mergedMap.set(customerID, {
            customer_id: customerID,
            id: item.id,
            customer_name: item.customer_name,
            projects: [project],
          });
        }
      });
      const mergedArray = Array.from(mergedMap.values());
      setClientDetails(mergedArray);
      }
  }

  const calculateGrandTotal = (details) => {
    const grandTotal = details.reduce((total, client) => {
      const projectTotal = client.projects.reduce((projectSum, project) => {
        const tempCost = typeof project.cost !== 'undefined' ? parseFloat(project.cost) : 0;
        return projectSum + tempCost;
      }, 0);
      return total + projectTotal;
    }, 0);
  
    setGrandTotal(grandTotal)
  }


  React.useEffect(() => {
    if (formOrigin==="distributor") {
      getDistributorDetails();
    } else {
      getBillingDetails();
    }
   
  }, []);
  const CustomerProjectsTable = () => {
    return (
      <div className="edit-purchased-items mb-5">
        <Table bordered>
          <thead>
            <tr>
              <th>Customer</th>
              <th>Project</th>
              <th>Project Date</th>
              <th>Billing Date</th>
              <th>Deadline</th>
              <th className="text-right">Cost</th>
            </tr>
          </thead>
          <tbody>
            {clientDetails.map((client, customerIndex) => (
              <React.Fragment key={customerIndex}>
                {client.projects.map((project, projectIndex) => (
                  <tr key={projectIndex}>
                    {projectIndex === 0 && (
                      <td rowSpan={client.projects.length} className="w-30">
                        <Form.Control value={client.customer_name} disabled/>
                      </td>
                    )}
                    <td>
                      <Form.Control value={project.project_name} disabled/>
                    </td>
                    <td>
                      <Form.Control
                        defaultValue={project.project_date}
                        type="date"
                        disabled
                      />
                    </td>
                    <td>
                      <Form.Control
                        value={project.billing_date}
                        type="date"
                        name="billing_date"
                        onChange={(e) => handleUpdate(e, customerIndex, projectIndex)}
                      />
                      {formErrors[customerIndex] && formErrors[customerIndex][projectIndex].billing_date && (
                        <div className="validity-error callout bottom">{formErrors[customerIndex][projectIndex].billing_date}</div>
                      )}
                    </td>
                    <td>
                      <Form.Control
                        value={project.due_date}
                        type="date"
                        name="due_date"
                        onChange={(e) => handleUpdate(e, customerIndex, projectIndex)}
                      />
                      {formErrors[customerIndex] && formErrors[customerIndex][projectIndex].due_date && (
                        <div className="validity-error callout bottom">{formErrors[customerIndex][projectIndex].due_date}</div>
                      )}
                    </td>
                    <td>
                      <Form.Control
                        value={project.cost}
                        type="number"
                        className="text-end"
                        name="cost"
                        onChange={(e) => handleUpdate(e, customerIndex, projectIndex)}
                      />
                      {formErrors[customerIndex] && formErrors[customerIndex][projectIndex].cost && (
                        <div className="validity-error callout bottom">{formErrors[customerIndex][projectIndex].cost}</div>
                      )}
                    </td>
                  </tr>
                ))}
              </React.Fragment>
            ))}
          </tbody>
        </Table>
        <Row className="mt-4">
          <Col xs={6}></Col>
          <Col xs={3} className="color-gray grand-total-text text-center">Grand Total</Col>
          <Col xs={1} className="grand-total-text">PHP</Col>
          <Col xs={2} className="text-end grand-total-text">
            {grandTotal
              ? numberFormat(grandTotal)
              : "0.00"}
          </Col>
        </Row>
      </div>
    );
  };
  
  return (
    <div>
      <div className="page">
        <Navbar
          onCollapse={(inactive) => {
            setInactive(inactive);
          }}
          active={"SALES"}
        />
      </div>
      <div className={`container ${inactive ? "inactive" : "active"}`}>
        <div className="print-container px-5 py-2" id="printablediv">
          <div className="print-grand-total my-3 text-end print-header d-flex flex-column">
            
            {
              formOrigin==="distributor" && (
                <span>DISTRIBUTOR NO. {passedDistributorId}</span>
              )
            }
            {
              formOrigin==="billing" && (
                <span>DISTRIBUTOR BILLING NO. {passedBillingId}</span>
              )
            }
            <span className="text-uppercase">
              {moment().format("MMMM DD, YYYY")}
            </span>
          </div>
          <div className="d-flex justify-content-center py-1">
            <img src={cleanLogo} className="print-logo" />
          </div>

          {/* content */}
          <div className="print-body mt-5">
          <Fragment>
            <Row className="mt-4 mb-2">
                <Col>
                    <span htmlFor="customer_id" className="edit-label">Distributor Name<label className='badge-required'>*</label></span>
                    <Form.Control
                      value={distributorDetails.name}
                      name="name"
                      disabled
                    />
                </Col>

              <Col>
              <span htmlFor="customer_id" className="edit-label">Address<label className='badge-required'>*</label></span>
                <Form.Control
                  type="text"
                  name="address"
                  value={distributorDetails.address}
                  disabled
                />
              </Col>
            </Row>

            <Row className="mt-3">
              <Col>
              <span htmlFor="customer_id" className="edit-label">CONTACT PERSON <label className='badge-required'>*</label></span>
                <Form.Control
                  type="text"
                  name="contact_person"
                  value={distributorDetails.contact_person}
                  disabled
                />
              </Col>
              <Col>
              <span htmlFor="customer_id" className="edit-label">CONTACT NUMBER<label className='badge-required'>*</label></span>
                <Form.Control
                  type="text"
                  name="contact_no"
                  value={distributorDetails.contact_no}
                  disabled
                />
              </Col>
            </Row>
          </Fragment>

          <Row className="mt-4 pt-3">
            <span className="edit-label mb-2">CLIENTS</span>
            <CustomerProjectsTable/>
          </Row>
            
          </div>
        </div>
        

        {/* footer */}
        <Row className="d-flex justify-content-end my-4 pb-5 d-flex-responsive">
          <Col xs={3}></Col>
          <Col xs={2}>
            <button
              className="button-secondary w-100"
              onClick={() => navigate(-1)}
            >
              Close
          </button>
          </Col>

          <Col xs={2}>
            <button className="button-primary w-100" onClick={()=>setConfirmAdd(true)}>
              Confirm Billing
            </button>
          </Col>
        </Row>

        <Modal show={confirmAdd} onHide={()=>setConfirmAdd(false)}>
          <Modal.Body className="p-4">
            Are you sure you want to submit this billing statement? This action cannot be undone.
            <hr></hr>
            <div className="col-sm-12 mt-1 d-flex justify-content-end">
              <button className="button-secondary me-3 py-0" onClick={() => setConfirmAdd(false)}>
                Cancel
              </button>
              <button className="button-primary py-0" onClick={handleSubmitBilling}>
                Confirm
              </button>
            </div>
          </Modal.Body>
        </Modal>
      </div>
    </div>
  );
}
