import React, { useState } from "react";
import { Col, Form, Row } from "react-bootstrap";
import { useNavigate, useParams } from "react-router-dom";
import toast from "react-hot-toast";
import {Select as AntSelect} from "antd";
import Select from "react-select";
import "react-autocomplete-input/dist/bundle.css";
import "react-bootstrap-typeahead/css/Typeahead.css";
import ReactLoading from "react-loading";
import upload from "../../Assets/Images/upload.png";

import {
  numberFormat,
  toastStyle,
  numberFormatInt,
} from "../../Helpers/Utils/Common";
import Navbar from "../../Components/Navbar/Navbar";
import "../Purchases/PurchaseOrders/PurchaseOrders.css";
import "./ProjectExpenses.css";
import { Fragment } from "react";
import { useEffect } from "react";

import { getAllProjects } from "../../Helpers/apiCalls/Manage/Projects";
import InputError from "../../Components/InputError/InputError";
import { validateProjectExpense } from "./../../Helpers/Validation/Project/ProjectValidation";
import { getAllExpenseType } from "./../../Helpers/apiCalls/expensetypesApi";
import { getAllSuppliers } from "../../Helpers/apiCalls/suppliersApi";

import { Upload } from "antd";
import {
  createProjectExpense,
  updateProjectExpense,
  getProjectExpense,
} from "./../../Helpers/apiCalls/ProjectInvoice/ProjectExpenseApi";
const { Dragger } = Upload;

function FormProjectExpenses({ add, edit }) {
  let navigate = useNavigate();
  const [inactive, setInactive] = useState(true);
  const [isSubmitClicked, setIsSubmitClicked] = useState(false);
  const { id } = useParams();

  // FRANCHISEE INVOICE DETAILS HANDLER
  const [formValues, setFormValues] = useState({
    amount: 0,
    other_fees: 0,
    project_id_value: {
      label: "",
      value: "",
    },
    project_price: "",
    expense_type_id_value: {
      label: "",
      value: "",
    },
    remarks: "",
  });

  const [projects, setProjects] = useState([]);
  const [expenseTypes, setExpenseTypes] = useState([]);
  const [allSuppliers, setAllSuppliers] = useState([]);
  const [fileList, setFileList] = useState([]);
  const [file, setFile] = useState([]);

  //ERROR HANDLING
  const [isError, setIsError] = useState({
    project_id: false,
    project_price: false,
    expense_type_id: false,
    amount: false,
    project_expense_date: false,
  });

  async function fetchAllProjects() {
    setProjects([]);
    const response = await getAllProjects();
    if (response.data) {
      let result = response.data.data.data.map((a) => {
        var info = a;
        // a.name =  "project_id";
        a.for = "project_id";
        a.select_value = "project_id_value";
        a.label = a.name;
        a.value = a.id;
        return info;
      });
      setProjects(result);
    }
  }

  async function fetchExpenseTypes() {
    setExpenseTypes([]);

    const response = await getAllExpenseType();
    var expenses = response.data.sort((a, b) =>
      a.name > b.name ? 1 : b.name > a.name ? -1 : 0
    );

    let result = expenses.map((expense) => {
      var info = expense;
      info.for = "expense_type_id";
      info.select_value = "expense_type_id_value";
      info.label = expense.name;
      info.value = expense.id;
      return info;
    });
    setExpenseTypes(result);
  }

  async function fetchAllSuppliers() {
    setAllSuppliers([]);
    const response = await getAllSuppliers();
    if (response.data) {
      const res = response.data.data.map((row) => {
        return {
          value: row.id,
          label: row.trade_name,
        };
      });
      setAllSuppliers(res);
    }
  }

  async function fetchProjectExpense() {
    const response = await getProjectExpense(id);
    if (response.data) {
      var data = response.data.data[0];
      data.amount = numberFormatInt(data.amount);
      data.other_fees = numberFormatInt(data.other_fees);
      // data.project_expense_date = data.project_project_expense_date;
      setFormValues(data);
    } else {
      toast.error(response.error.data.messages.error, {
        style: toastStyle(),
      });
    }
  }

  //ADD FUNCTIONS
  async function handleCreateProjectExpense() {
    if (isSubmitClicked) {
      return;
    }
    if (validateProjectExpense(formValues, setIsError)) {
      setIsSubmitClicked(true);
      const response = await createProjectExpense(formValues, fileList);
      if (response.data) {
        if (response.data?.message === "Data already exists") {
          toast.error("Data already exists", { style: toastStyle() });
        } else {
          toast.success("Successfully created Project Expense", {
            style: toastStyle(),
          });
          // addPayment(response.data.franchisee_id);
          setTimeout(
            () =>
              navigate(
                "/projectexpense/view/" +
                  response.data.project_expense_id +
                  "/pending"
              ),
            1000
          );
        }
      } else {
        toast.error(response.error.data.messages.error, {
          style: toastStyle(),
        });
      }
      setIsSubmitClicked(false);
    } else {
      toast.error("Please fill in all fields", {
        style: toastStyle(),
      });
    }
  }

  //EDIT FUNCTIONS
  async function handleUpdateProjectExpense() {
    if (validateProjectExpense(formValues, setIsError)) {
      const response = await updateProjectExpense(formValues, fileList);
      if (response.data) {
        toast.success("Successfully updated Project Expense", {
          style: toastStyle(),
        });
        setTimeout(() => navigate("/projectexpense"), 1000);
      } else {
        toast.error(response.error.data.messages.error, {
          style: toastStyle(),
        });
      }
    } else {
      toast.error("Please fill in all fields", {
        style: toastStyle(),
      });
    }
  }

  //HANDLES
  const handleSubmit = () => {
    if (add) handleCreateProjectExpense();
    else if (edit) handleUpdateProjectExpense();
  };

  function handleAddDetailsChange(e, search) {
    if (search) {
      if (e.for === "project_id") {
        setFormValues((prevState) => ({
          ...prevState,
          [e.for]: e.value,
          [e.select_value]: {
            for: e.for,
            label: e.label,
            value: e.value,
          },
          project_price: e.project_price ?? "0.00",
        }));
      } else {
        setFormValues((prevState) => ({
          ...prevState,
          [e.for]: e.value,
          [e.select_value]: {
            for: e.for,
            label: e.label,
            value: e.value,
          },
        }));
      }
    } else {
      const newList = { ...formValues };
      const { name, value } = e.target;
      newList[name] = value;
      setFormValues(newList);
    }
  }

  const handleBeforeUpload = (file) => {
    setFileList([...fileList, file]);
    return false;
  };

  const handleRemove = (selectedFile) => {
    var newlist = fileList.filter((file) => {
      return selectedFile.uid !== file.uid;
    });
    setFileList(newlist);
  };

  //USE EFFECTS
  useEffect(() => {
    if (edit) {
      fetchProjectExpense();
    }
    fetchAllProjects();
    fetchExpenseTypes();
    fetchAllSuppliers();
  }, []);

  useEffect(() => {
    var grandtotal =
      parseFloat(formValues.amount ? formValues.amount : 0) +
      parseFloat(formValues.other_fees ? formValues.other_fees : 0);
    setFormValues((prevState) => ({
      ...prevState,
      grand_total: grandtotal,
    }));
  }, [formValues.amount, formValues.other_fees]);

  return (
    <div>
      <div className="page">
        <Navbar
          onCollapse={(inactive) => {
            setInactive(inactive);
          }}
          active={"SALES"}
        />
      </div>

      <div className={`container ${inactive ? "inactive" : "active"}`}>
        {/* header */}
        <div className="d-flex justify-content-between align-items-center my-3 pb-4">
          <h1 className="page-title mb-0">
            {add ? "ADD " : "EDIT "}PROJECT EXPENSE
          </h1>
        </div>

        {/* content */}
        <div className="edit-form">
          {/* FRANCHISEE SALES INVOICE DETAILS */}
          <Fragment>
            <Row className="mt-4">
              <Col xs={6}>
                <span className="edit-label">
                  Project Name
                  <label className="badge-required">{` *`}</label>
                </span>
                <Select
                  name="project_id"
                  className="react-select-container"
                  classNamePrefix="react-select"
                  placeholder="Select Project..."
                  options={projects}
                  value={projects.find(
                    (option) => option.value === formValues.project_id
                  )}
                  // defaultValue={projects.find(option => option.value === id)}
                  onChange={(e) => handleAddDetailsChange(e, true)}
                />
                <InputError
                  isValid={isError.project_id}
                  message={"Project is required"}
                />
              </Col>
              <Col xs={3}>
                <span className="edit-label">Project Price</span>
                <Form.Control
                  type="text"
                  disabled
                  name="project_price"
                  className="nc-modal-custom-text"
                  value={
                    formValues.project_price
                      ? numberFormat(formValues.project_price)
                      : ""
                  }
                />
              </Col>
              {/* <Col xs={3}>
                <span className="edit-label">
                  Project Net Sales
                  <label className="badge-required">{` *`}</label>
                </span>
                <Form.Control
                  type="text"
                  disabled
                  name="project_net_sales"
                  className="nc-modal-custom-text"
                  value={
                    formValues.paid_amount
                      ? numberFormat(formValues.paid_amount)
                      : 0.0
                  }
                  onChange={(e) => handleAddDetailsChange(e)}
                />
              </Col> */}
              <Col xs={3}>
                <span className="edit-label">Expense Date</span>
                <label className="badge-required">{` *`}</label>
                <Form.Control
                  type="date"
                  name="project_expense_date"
                  className="nc-modal-custom-text"
                  defaultValue={formValues.project_expense_date}
                  onChange={(e) => {
                    setFormValues((prev) => ({
                      ...prev,
                      project_expense_date: e.target.value,
                    }));
                  }}
                />
                <InputError
                  isValid={isError.project_expense_date}
                  message={"Expense date is required"}
                />
              </Col>
            </Row>
            <Row className="mt-4">
              <Col xs={4}>
                <span className="edit-label">Account Type</span>
                <label className="badge-required">{` *`}</label>
                <Select
                  name="type"
                  className="react-select-container"
                  classNamePrefix="react-select"
                  placeholder="Select Account Type..."
                  value={expenseTypes.find(
                    (option) => option.value === formValues.expense_type_id
                  )}
                  options={expenseTypes}
                  // onChange={(e) => handleAddDetailsChange(e, true)}
                  onChange={(e) =>
                    setFormValues((prev) => ({
                      ...prev,
                      expense_type_id: e.value,
                    }))
                  }
                />
                <InputError
                  isValid={isError.expense_type_id}
                  message={"Account Type is required"}
                />
              </Col>
              <Col xs={4}>
                <span className="edit-label">Supplier</span>
              
                <AntSelect className="w-100" options={allSuppliers} onChange={(e) =>
                    setFormValues((prev) => ({ ...prev, supplier_id: e }))
                  } showSearch filterOption={(input, option) => (option.label?.toLowerCase().includes(input?.toLowerCase()))} 
                  value={formValues.supplier_id} placeholder="Select supplier"/>
                
              </Col>
            </Row>
            <Row className="mt-4">
              <Col xs={12}>
                <span className="edit-label">Remarks</span>
                <Form.Control
                  as="textarea"
                  name="remarks"
                  className="nc-modal-custom-text"
                  value={formValues.remarks}
                  onChange={(e) => handleAddDetailsChange(e)}
                />
              </Col>
            </Row>
          </Fragment>
          <Row className="mt-4 pt-3">
            <Col xs={12}>
              <Dragger
                {...{
                  // style: { width: 550 },
                  file,
                  defaultFileList: file,
                  onRemove: handleRemove,
                  beforeUpload: handleBeforeUpload,
                  multiple: false,
                  // onChange: handleOnChange,
                  listType: "picture",
                  progress: { showInfo: true },
                  data: (file) => {},
                }}
              >
                <img src={upload} className="cursor-pointer" alt="" />
                <p className="ant-upload-text">
                  Click or drag file to this area to upload
                </p>
                {/* <p className="ant-upload-hint">
                            Support for a single or bulk upload.
                        </p>  */}
              </Dragger>
            </Col>
          </Row>
          <Row className="align-right pt-3">
            <Col xs={2} className="text-end">
              <span className="edit-label color-gray">
                Amount
                <label className="badge-required">{` *`}</label>
              </span>
            </Col>
            <Col xs={1} className="text-end">
              <span className="edit-label align-middle">PHP</span>
            </Col>
            <Col xs={3} className="text-end">
              <Form.Control
                type="number"
                name="amount"
                min={0}
                step="0.01"
                value={formValues.amount}
                className="align-middle nc-modal-custom-text text-end"
                onChange={(e) => handleAddDetailsChange(e)}
              />
              <InputError
                isValid={isError.amount}
                message={"amount is required"}
              />
            </Col>
          </Row>
          <Row className="align-right pt-3">
            <Col xs={2} className="text-end">
              <span className="edit-label color-gray">Other Fees</span>
            </Col>
            <Col xs={1} className="text-end">
              <span className="edit-label align-middle">PHP</span>
            </Col>
            <Col xs={3} className="text-end">
              <Form.Control
                type="number"
                name="other_fees"
                min={0}
                step="0.01"
                value={formValues.other_fees}
                className="align-middle nc-modal-custom-text text-end"
                onChange={(e) => handleAddDetailsChange(e)}
              />
              <InputError
                isValid={isError.other_fees}
                message={"Other fee is required"}
              />
            </Col>
          </Row>
          <Row className="align-right py-5">
            <Col xs={2} className="text-end">
              <span className="edit-label color-gray grand-total-text">
                Grand Total
              </span>
            </Col>
            <Col xs={1} className="text-end">
              <span className="edit-label align-middle grand-total-text">
                PHP
              </span>
            </Col>
            <Col xs={3} className="text-end">
              <span className="edit-label align-middle grand-total-text">
                {numberFormat(formValues.grand_total)}
              </span>
            </Col>
          </Row>

          {/* FOOTER: CANCEL & SUBMIT BUTTONS */}
          <div className="d-flex justify-content-end pt-5 pb-3">
            <button
              type="button"
              className="button-secondary me-3"
              onClick={() => navigate(-1)}
            >
              Cancel
            </button>
            {isSubmitClicked ? (
              <div className="button-primary d-flex justify-content-center">
                <ReactLoading
                  type="bubbles"
                  color="#FFFFFF"
                  height={50}
                  width={50}
                />
              </div>
            ) : formValues.project_id === "" ||
              formValues.project_expense_date === "" ||
              formValues.expense_type_id === "" ? (
              <button type="button" className="button-primary">
                Submitxx
              </button>
            ) : (
              <button
                type="button"
                className="button-primary"
                onClick={handleSubmit}
              >
                Submit
              </button>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

FormProjectExpenses.defaultProps = {
  add: false,
  edit: false,
};

export default FormProjectExpenses;
