import React, { useState } from "react";
import { Col, Container, Form, Row } from "react-bootstrap";

//components
import Table from "../../Components/TableTemplate/DataTable";
import Navbar from "../../Components/Navbar/Navbar";
import DeleteModal from "../../Components/Modals/DeleteModal";
import AddModal from "../../Components/Modals/AddModal";
import EditModal from "../../Components/Modals/EditModal";
import ViewModal from "../../Components/Modals/ViewModal";
import InputError from "../../Components/InputError/InputError";
import {
  createExpenseType,
  deleteExpenseType,
  editExpenseType,
  getAllExpenseType,
} from "../../Helpers/apiCalls/expensetypesApi";
import { isAdmin, refreshPage, toastStyle } from "../../Helpers/Utils/Common";
import { validateExpenseTypes } from "../../Helpers/Validation/Manage/ExpenseTypesValidation";
import toast from "react-hot-toast";

export default function ExpenseTypes() {
  const [inactive, setInactive] = useState(false);
  const [showLoader, setShowLoader] = useState(false);
  const [isClicked, setIsClicked] = useState(false);
  const [searchText, setSearchText] = useState("");

  // MODALS //
  // DELETE
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const handleShowDeleteModal = () => setShowDeleteModal(true);
  const handleCloseDeleteModal = () => setShowDeleteModal(false);

  // VIEW
  const [showViewExpenseTypeModal, setShowViewExpenseTypeModal] =
    useState(false);
  const handleShowViewExpenseTypeModal = () =>
    setShowViewExpenseTypeModal(true);
  const handleCloseViewExpenseTypeModal = () =>
    setShowViewExpenseTypeModal(false);

  // EDIT
  const [showEditExpenseTypeModal, setShowEditExpenseTypeModal] =
    useState(false);
  const handleShowEditExpenseTypeModal = () =>
    setShowEditExpenseTypeModal(true);
  const handleCloseEditExpenseTypeModal = () =>
    setShowEditExpenseTypeModal(false);


  // ADD
  const [showAddExpenseTypeModal, setShowAddExpenseTypeModal] = useState(false);
  const handleShowAddExpenseTypeModal = () => setShowAddExpenseTypeModal(true);
  const handleCloseAddExpenseTypeModal = () => {
    setShowAddExpenseTypeModal(false); setExpenseTypeDetails({})}

  //API
  const [allData, setAllData] = useState([]);
  const [expensetypeData, setExpenseTypeData] = useState({});
  const [selectedID, setSelectedID] = useState("");
  const [expensetypeDetails, setExpenseTypeDetails] = useState({
    name: "",
    description: "",
  });

  //REQUIRED ERROR HANDLING
  const [isError, setIsError] = useState({
    name: false,
  });

  const [isErrorEdit, setIsErrorEdit] = useState({
    name: false,
  });

  //ONCHANGE
  //ADD or CREATE FORWARDER
  const handleAddChange = (e) => {
    const { name, value } = e.target;
    setExpenseTypeDetails((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };
  //onEdit
  function handleOnEdit() {
    handleCloseViewExpenseTypeModal();
    handleShowEditExpenseTypeModal();
  }

  //EDIT OR UPDATE FORWARDER
  const handleEditChange = (e) => {
    const { name, description, value } = e.target;
    setExpenseTypeData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  //DELETE or REMOVE FORWARDER
  function handleDeleteExpenseType() {
    removeExpenseType(selectedID);
  }

  //DROPDOWN
  function handleSelectChange(e, row) {
    setExpenseTypeData(row);
    setSelectedID(row.id);
    if (e.target.value === "delete-expensetype") {
      handleShowDeleteModal();
    } else if (e.target.value === "edit-expensetype") {
      handleShowEditExpenseTypeModal();
    } else if (e.target.value === "view-expensetype") {
      handleShowViewExpenseTypeModal();
    } else {
      handleShowDeleteModal();
    }
  }

  const filteredData = allData.filter((item) =>
    item.name.toLowerCase().includes(searchText.toLowerCase())
  );

  function ActionBtn(row) {
    return (
      <Form.Select
        name="action"
        className="PO-select-action form-select"
        id={row.id}
        onChange={(e) => handleSelectChange(e, row)}
        value={""}
      >
        <option defaulValue selected hidden>
          Select
        </option>
        <option value="view-expensetype" className="color-options">
          View
        </option>
        {isAdmin && (
          <option value="edit-expensetype" className="color-options">
            Edit
          </option>
        )}
        {isAdmin && (
          <option value="delete-expensetype" className="color-red">
            Delete
          </option>
        )}
      </Form.Select>
    );
  }

  //API CALL
  async function fetchAllExpenseType() {
    setShowLoader(true);
    const response = await getAllExpenseType();
    if (response.data) {
      setAllData(response.data);
    } else {
      setAllData([]);
    }
    setShowLoader(false);
  }

  async function handleSaveExpenseType() {
    if (validateExpenseTypes(expensetypeDetails, setIsError)) {
      setIsClicked(true);

      // Check if the expense type already exists
      const existingExpenseType = allData.find(
        (expensetype) =>
          expensetype.name.toLowerCase() ===
          expensetypeDetails.name.toLowerCase()
      );

      if (existingExpenseType) {
        toast.error("Account type already exists!", {
          style: toastStyle(),
        });
        setIsClicked(false);
        return;
      }
      const response = await createExpenseType(expensetypeDetails);
      
      if (response.data) {
        toast.success("Successfully created account type!", {
          style: toastStyle(),
        });
        handleCloseAddExpenseTypeModal();
        fetchAllExpenseType();
      } else {
        toast.error(response.error.data.messages.error, {
          style: toastStyle(),
        });
      }
      setIsClicked(false);
    } else {
      toast.error("An error has occured!", { style: toastStyle() });
    }
  }

  async function handleEditExpenseType() {
    if (validateExpenseTypes(expensetypeData, setIsErrorEdit)) {
      setIsClicked(true);
      const response = await editExpenseType(expensetypeData);
      if (response.data) {
        toast.success("Successfully updated account type!", {
          style: toastStyle(),
        });
        handleCloseEditExpenseTypeModal();
        // refreshPage();
        fetchAllExpenseType();
      } else {
        toast.error(response.error.data.messages.error, {
          style: toastStyle(),
        });
      }
      setIsClicked(false);
    } else {
      toast.error("An error has occured!", { style: toastStyle() });
    }
  }

  async function removeExpenseType(id) {
    const response = await deleteExpenseType(id);
    if (response.data) {
      toast.success("Successfully delete account type!", {
        style: toastStyle(),
      });
      handleCloseDeleteModal();
      fetchAllExpenseType();
    }
  }

  React.useEffect(() => {
    fetchAllExpenseType();
  }, []);

  return (
    <div>
      <div className="page">
        <Navbar
          onCollapse={(inactive) => {
            setInactive(inactive);
          }}
          active={"MANAGE"}
        />
      </div>
      <div className={`manager-container ${inactive ? "inactive" : "active"}`}>
        <Row className="mb-4">
          <Col>
            <h1 className="page-title"> ACCOUNT TYPE </h1>
          </Col>
          <Col className="d-flex justify-content-end">
            <input
              type="search"
              name="name"
              placeholder="Search Name..."
              value={searchText}
              onChange={(e) => setSearchText(e.target.value)}
              className="search-bar"
            />
            <button className="add-btn" onClick={handleShowAddExpenseTypeModal}>
              Add
            </button>
          </Col>
        </Row>
        <div className="tab-content">
          {/* TABLE */}
          <Table
            tableHeaders={["NAME", "DESCRIPTION", "ACTIONS"]}
            headerSelector={["name", "description"]}
            tableData={filteredData}
            ActionBtn={(row) => ActionBtn(row)}
            showLoader={showLoader}
          />
        </div>
      </div>

      {/* MODALS */}
      <DeleteModal
        text="expense type"
        show={showDeleteModal}
        onHide={handleCloseDeleteModal}
        onDelete={handleDeleteExpenseType}
      />

      <AddModal
        title="ACCOUNT TYPE"
        size="lg"
        type="expensetype"
        show={showAddExpenseTypeModal}
        onHide={handleCloseAddExpenseTypeModal}
        onSave={handleSaveExpenseType}
        isClicked={isClicked}
        data={expensetypeDetails}
      >
        <div className="mt-3">
          <Row className="nc-modal-custom-row mt-0">
            <Col>
              NAME
              <span className="required-icon">*</span>
              <Form.Control
                type="text"
                name="name"
                className="nc-modal-custom-input"
                onChange={(e) => handleAddChange(e)}
                required
              />
              <InputError isValid={isError.name} message={"Name is required"} />
            </Col>
          </Row>
          <Row className="nc-modal-custom-row">
            <Col>
              DESCRIPTION
              <Form.Control
                type="text"
                name="description"
                className="nc-modal-custom-input"
                onChange={(e) => handleAddChange(e)}
                required
              />
            </Col>
          </Row>
        </div>
      </AddModal>

      <EditModal
        title="ACCOUNT TYPE"
        size="lg"
        type="forwarder"
        show={showEditExpenseTypeModal}
        onHide={handleCloseEditExpenseTypeModal}
        onSave={handleEditExpenseType}
        data={expensetypeData}
      >
        <div className="mt-3">
          <Row className="nc-modal-custom-row mt-0">
            <Col>
              NAME
              <span className="required-icon">{expensetypeData.name === "" ? "*" : ""}</span>
              <Form.Control
                type="text"
                name="name"
                value={expensetypeData.name}
                className="nc-modal-custom-input-edit"
                onChange={(e) => handleEditChange(e)}
                required
              />
              <InputError
                isValid={isErrorEdit.name}
                message={"Name is required"}
              />
            </Col>
          </Row>
          <Row className="nc-modal-custom-row">
            <Col>
              DESCRIPTION
              <Form.Control
                type="text"
                name="description"
                value={expensetypeData.description}
                className="nc-modal-custom-input-edit"
                onChange={(e) => handleEditChange(e)}
                required
              />
            </Col>
          </Row>
        </div>
      </EditModal>

      <ViewModal
        withButtons
        show={showViewExpenseTypeModal}
        onHide={handleCloseViewExpenseTypeModal}
        onEdit={handleOnEdit}
      >
        <div className="mt-1">
          <span className="custom-modal-body-title-expensetype-details">
            ACCOUNT TYPES DETAILS
          </span>
          <Row className="nc-modal-custom-row mt-3">
            <Col>
              NAME
              <Form.Control
                type="text"
                name="name"
                value={expensetypeData.name}
                className="nc-modal-custom-input-edit"
                disabled
              />
            </Col>
          </Row>
          <Row className="nc-modal-custom-row">
            <Col>
              DESCRIPTION
              <Form.Control
                type="text"
                name="description"
                value={expensetypeData.description}
                className="nc-modal-custom-input-edit"
                disabled
              />
            </Col>
          </Row>
        </div>
      </ViewModal>
    </div>
  );
}
