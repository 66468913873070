import { handleValidationChange } from "../CommonValidation";

export const validateCustomer = (data, setIsError, type) => {
  var isValid = true;
  
  if (data.company === "") {
    handleValidationChange("company", true, setIsError);
    isValid = false;
  } else {
    handleValidationChange("company", false, setIsError);
  }

  if (data.name === "") {
    handleValidationChange("name", true, setIsError);
    isValid = false;
  } else {
    handleValidationChange("name", false, setIsError);
  }

  if (data.address === "") {
    handleValidationChange("address", true, setIsError);
    isValid = false;
  } else {
    handleValidationChange("address", false, setIsError);
  }

  return isValid;
};
