import React, { useState } from "react";
import { Button, Col, Form, Row, Table } from "react-bootstrap";
import { useNavigate, useParams } from "react-router-dom";
import { Modal } from "react-bootstrap";
import toast from "react-hot-toast";
import Select from "react-select";
import TextInput from "react-autocomplete-input";
import "react-autocomplete-input/dist/bundle.css";
import { setMinutes, setHours } from "date-fns";
import { Typeahead } from "react-bootstrap-typeahead";
import "react-bootstrap-typeahead/css/Typeahead.css";
import ReactLoading from "react-loading";

import {
  formatDateNoTime,
  getTodayDate,
  getTodayDateISO,
  getTodayDateNoTime,
  numberFormat,
  refreshPage,
  toastStyle,
  TokenExpiry,
} from "../../Helpers/Utils/Common";
import Navbar from "../../Components/Navbar/Navbar";
import "../Purchases/PurchaseOrders/PurchaseOrders.css";
import "./Franchise.css";
import { Fragment } from "react";
import { useEffect } from "react";
import { createProject } from "../../Helpers/apiCalls/Manage/Projects";

import { getAllProjects } from "../../Helpers/apiCalls/Manage/Projects";
import { getAllCustomer } from "../../Helpers/apiCalls/Manage/CustomerAPI";
import { createBranchPotato } from "../../Helpers/apiCalls/PotatoCorner/Manage/Branches";
import {
  createFranchisee,
  createFranchiseePayment,
  getAllFranchisee,
  getFranchisee,
  updateFranchisee,
} from "../../Helpers/apiCalls/Franchise/FranchiseApi";
import { validateFranchise } from "../../Helpers/Validation/Franchise/FranchiseValidation";
import InputError from "../../Components/InputError/InputError";
import { validateFranchiseEdit } from "../../Helpers/Validation/Franchise/FranchiseEditValidation";
import { validateBranches } from "../../Helpers/Validation/Manage/BanchesValidation";
import ReactDatePicker from "react-datepicker";
import AddModal from "../../Components/Modals/AddModal";
import { getAllBanks } from "../../Helpers/apiCalls/banksAPi";
import { getAllPriceLevels } from "../../Helpers/apiCalls/Manage/PriceLevels";
import { getType } from "../../Helpers/Utils/Common";
import {
  getAllBranchGroup,
  getAllBranchGroupPotato,
} from "../../Helpers/apiCalls/Manage/BranchGroupApi";
import {
  getAllInventoryGroup,
  getAllInventoryGroupPotato,
} from "../../Helpers/apiCalls/Manage/InventoryGroup";

/**
 *  -- COMPONENT: FORM TO ADD OR EDIT FRANCHISEE SALES INVOICE
 */
function FormFranchiseInvoice({ add, edit }) {
  let navigate = useNavigate();
  const [inactive, setInactive] = useState(true);
  const [isBranchClicked, setIsBranchClicked] = useState(false);
  const [isSubmitClicked, setIsSubmitClicked] = useState(false);
  const [isClicked, setIsClicked] = useState(false);
  const userType = getType();
  /**
   *  @po_id - param for add purchase invoice form
   *  @pi_id - param for edit purchase invoice form
   */
  const { po_id, pi_id } = useParams();
  const today = getTodayDateISO();

  // FRANCHISEE INVOICE DETAILS HANDLER
  const [franchiseeInvoice, setFranchiseeInvoice] = useState({
    franchised_on: today,
    project_id: "",
    customer_id: "",
    address: "",
    name: "",
    opening_start: "",
    contract_start: "",
    contract_end: "",
    contact_person: "",
    contact_number: "",
    phone_no: "",
    franchisee_contact_no: "",
    email: "",
    franchisee_fee: 0,
    package_type: "",
    security_deposit: 0,
    taxes: 0,
    other_fee: 0,
    royalty_fee: 0,
    marketing_fee: 0,
    remarks: "",
    term_days: "",
    amount: "",
    payment_method: "",
    payment_date: today,
    deposit_date: today,
    bank_name: "",
    cheque_number: "",
    cheque_date: "",
    reference_number: "",
    transaction_number: "",
    deposited_to: "",
    invoice_no: "",
    beginning_credit_limit: "",
  });

  const [banks, setBanks] = useState([]);
  const [osStartTime, setOsStartTime] = useState(new Date());
  const [osEndTime, setOsEndTime] = useState(new Date());
  const [priceLevel, setPriceLevels] = useState([]);

  const [deliveryStartTime, setDeliveryStartTime] = useState(new Date());
  const [deliveryEndTime, setDeliveryEndTime] = useState(new Date());
  const [franchiseeName, setFranchiseeName] = useState([]);
  const [branchGroup, setBranchGroup] = useState([]);
  const [inventoryGroup, setInventoryGroup] = useState([]);

  const [addBranchData, setAddBranchData] = useState({
    name: "",
    company: "",
    address: "",
    phone_no: "",
    tin_no: "",
    contact_person: "",
  });

  //ERROR HANDLING
  const [isError, setIsError] = useState({
    name: false,
    company: false,
    address: false,
    phone_no: false,
    tin_no: false,
    contact_person: false,
    project_id: false,
  });

  //ERROR HANDLING
  const [isErrorBranch, setIsErrorBranch] = useState({
    // name: false,
    company: false,
    address: false,
    phone_no: false,
    tin_no: false,
    contact_person: false,
  });

  const [grandTotal, setGrandTotal] = useState(0);
  const [addTo, setAddTo] = useState("");

  //MODAL HANDLERS
  const [showAddBranchModal, setShowAddBranchModal] = useState(false);
  const handleShowAddBranchModal = () => setShowAddBranchModal(true);
  const handleCloseAddBranchModal = () => {
    setShowAddBranchModal(false);
  };

  //MODAL HANDLERS
  const [showAddModal, setShowAddModal] = useState(false);
  const handleShowAddModal = () => setShowAddModal(true);
  const handleCloseAddModal = () => {
    setShowAddModal(false);
  };

  // DATA HANDLERS
  const [franchiseeBranches, setFranchiseeBranches] = useState([]);
  const [customersData, setCustomersData] = useState([]);
  const [franchiseeBranchesInfo, setFranchiseeBranchesInfo] = useState([]);
  const [customerInfo, setCustomerInfo] = useState([]);
  const [selectedBranch, setSelectedBranch] = useState({});
  const [franchisees, setFranchisees] = useState([]);

  //FETCH FUNCTIONS
  async function fetchBranches() {
    setFranchiseeBranches([]);
    const response = await getAllProjects();
    let isFranchisee = [];

    // if (response) {
    if (
      response &&
      response.data &&
      response.data.data &&
      response.data.data.data
    ) {
      let result = response.data.data.data.map((a) => {
        return {
          label: a.name,
          value: a.id,
          address: a.address,
          contact_person: a.contact_person,
          phone_no: a.phone_no,
          is_franchise: a.is_franchise,
        };
      });
      // isFranchisee = result.filter((bill) => bill.is_franchise === "1");
      isFranchisee = result.filter((bill) => Boolean(bill.is_franchise));
      setFranchiseeBranches(isFranchisee);
      setFranchiseeBranchesInfo(isFranchisee);
    }
  }

  async function fetchCustomer() {
    setCustomersData([]);
    const response = await getAllCustomer();
    let isCustomer = [];

    if (
      response &&
      response.data &&
      response.data.data &&
      response.data.data.data
    ) {

      let result = response.data.data.data.map((a) => {
        return {
          label: a.name,
          value: a.id,
          contact_number: a.contact_number,
          email: a.email,
        };
      });
      setCustomersData(result);
      setCustomerInfo(result);
    }
  }

  async function fetchPriceLevel() {
    setPriceLevels([]);
    const response = await getAllPriceLevels();

    if (response) {
      setPriceLevels(response.data.data.data);
    }
  }

  async function fetchFranchisee() {
    const response = await getFranchisee(pi_id);
    if (response.data) {
      setFranchiseeInvoice(response.data.data[0]);
      setFranchiseeName([
        {
          name: response.data.data[0].name,
          id: response.data.data[0].id,
          customOption: false,
        },
      ]);
    } else {
      toast.error(response.error.data.messages.error, {
        style: toastStyle(),
      });
    }
  }

  async function fetchAllFranchisees() {
    const response = await getAllFranchisee();
    if (response.data) {
      let result = response.data.data
        .sort()
        .filter(function (item, pos, arr) {
          return !pos || item.name !== arr[pos - 1].name;
        })
        .map((a) => {
          return a;
        });
      setFranchisees(result);
    } else {
      toast.error(response.error.data.messages.error, {
        style: toastStyle(),
      });
    }
  }

  async function fetchBanks() {
    const response = await getAllBanks();
    if (response.error) {
      TokenExpiry(response.error);
    } else {
      setBanks(response.data.data);
    }
  }

  async function fetchBranchGroup() {
    setBranchGroup([]);
    //Mango
    if (addTo === "MANGO MAGIC") {
      const response = await getAllBranchGroup({});
      if (response.data) {
        var result = response.data.data;
        var mangoBranches = result.map((branch) => {
          return {
            type: "mango",
            id: branch.id,
            name: branch.name,
          };
        });
        setBranchGroup(mangoBranches);
      } else if (response.error) {
      }
    }
    //Potato
    if (addTo === "POTATO CORNER") {
      const response2 = await getAllBranchGroupPotato({});

      if (response2.data) {
        var result = response2.data.data;
        var potatoBranches = result.map((branch) => {
          return {
            type: "potato",
            id: branch.id,
            name: branch.name,
          };
        });
        setBranchGroup(potatoBranches);
      } else if (response2.error) {
      }
    }
  }

  async function fetchInventoryGroup() {
    setBranchGroup([]);
    //Mango
    if (addTo === "MANGO MAGIC") {
      const response = await getAllInventoryGroup({});
      if (response.data) {
        var result = response.data.data;
        var mangoInventory = result.map((inventory) => {
          return {
            type: "mango",
            id: inventory.id,
            name: inventory.name,
          };
        });
        setInventoryGroup(mangoInventory);
      } else if (response.error) {
      }
    }
    //Potato
    if (addTo === "POTATO CORNER") {
      const response2 = await getAllInventoryGroupPotato({});

      if (response2.data) {
        var result = response2.data.data;
        var potatoInventory = result.map((inventory) => {
          return {
            type: "potato",
            id: inventory.id,
            name: inventory.name,
          };
        });
        setInventoryGroup(potatoInventory);
      } else if (response2.error) {
      }
    }
  }

  async function fetchPriceLevel() {
    setPriceLevels([]);
    const response = await getAllPriceLevels();

    if (response) {
      setPriceLevels(response.data.data.data);
    }
  }

  //ADD FUNCTIONS
  async function handleCreatePI() {
    if (isSubmitClicked) {
      return;
    }
    if (validateFranchise(franchiseeInvoice, setIsError)) {
      setIsSubmitClicked(true);
      const response = await createFranchisee(franchiseeInvoice);
      if (response.data) {
        if (response.data?.message === "Data already exists") {
          toast.error("Data already exists", { style: toastStyle() });
        } else {
          toast.success("Successfully created Project", {
            style: toastStyle(),
          });
          addPayment(response.data.franchisee_id);
          setTimeout(
            () => navigate("/franchise/print/" + response.data.franchisee_id),
            1000
          );
        }
      } else {
        toast.error(response.error.data.messages.error, {
          style: toastStyle(),
        });
      }
    } else {
      toast.error("Please fill in all fields", {
        style: toastStyle(),
      });
    }
  }

  async function create() {
    if (isBranchClicked) {
      return;
    }
    if (validateBranches(addBranchData, setIsError, addTo)) {
      if (addTo === "MANGO MAGIC") {
        const response = await createProject(addBranchData, {
          osStartTime: osStartTime,
          osEndTime: osEndTime,
          deliveryStartTime: deliveryStartTime,
          deliveryEndTime: deliveryEndTime,
        });
        if (response) {
          if (response?.data?.status === "success") {
            toast.success("Successfully added project", {
              style: toastStyle(),
            });
            refreshPage();
            setAddBranchData([]);
            handleCloseAddBranchModal();
          }
          if (response?.error) {
            toast.error(response.error.data.messages.error, {
              style: toastStyle(),
            });
            handleCloseAddBranchModal();
          }
        }
      } else if (addTo === "POTATO CORNER") {
        const response = await createBranchPotato(addBranchData, {
          osStartTime: osStartTime,
          osEndTime: osEndTime,
          deliveryStartTime: deliveryStartTime,
          deliveryEndTime: deliveryEndTime,
        });
        if (response) {
          if (response?.data?.status === "success") {
            toast.success("Successfully added project!", {
              style: toastStyle(),
            });
            refreshPage();
            handleCloseAddBranchModal();
          }
          if (response?.error) {
            toast.error(response.error.data.messages.error, {
              style: toastStyle(),
            });
            handleCloseAddBranchModal();
          }
        }
      }
    }
  }

  //EDIT FUNCTIONS
  async function handleUpdatePI() {
    if (validateFranchiseEdit(franchiseeInvoice, setIsError)) {
      if (
        // parseFloat(franchiseeInvoice.beginning_credit_limit) >=
        parseFloat(franchiseeInvoice.payable_credit)
      ) {
        const response = await updateFranchisee(pi_id, franchiseeInvoice);
        if (response.data) {
          toast.success("Successfully updated Project", {
            style: toastStyle(),
          });
          setTimeout(() => navigate("/franchise"), 1000);
        } else {
          toast.error(response.error.data.messages.error, {
            style: toastStyle(),
          });
        }
      } else if (
        // parseFloat(franchiseeInvoice.beginning_credit_limit) <=
        parseFloat(franchiseeInvoice.payable_credit) &&
        userType === "admin"
      ) {
        const response = await updateFranchisee(pi_id, franchiseeInvoice);
        if (response.data) {
          toast.success("Successfully updated Project", {
            style: toastStyle(),
          });
          setTimeout(() => navigate("/franchise"), 1000);
        } else {
          toast.error(response.error.data.messages.error, {
            style: toastStyle(),
          });
        }
        // } else {
        //   toast.error("Credit limit is less than current credit", {
        //     style: toastStyle(),
        //   });
      }
    } else {
      toast.error("Please fill in all fields", {
        style: toastStyle(),
      });
    }
  }

  //PAYMENT FUNCTION
  async function addPayment(id) {
    const response = await createFranchiseePayment(
      id,
      franchiseeInvoice.project_id,
      franchiseeInvoice
    );
    if (response.data) {
      toast.success("Successfully paid!", { style: toastStyle() });
    } else {
      toast.error(response.error.data.messages.error, {
        style: toastStyle(),
      });
    }
  }

  //HANDLES
  const handleSubmit = () => {
    if (add) handleCreatePI();
    else if (edit) handleUpdatePI();
  };

  const handleAddChange = (e) => {
    const { name, value } = e.target;
    setAddBranchData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleChange = (e, isSelect) => {
    if (isSelect) {
      const { name, value } = e.target;
      franchiseeBranchesInfo
        .filter((info) => info.value === value)
        .map((data) => {
          setFranchiseeInvoice((prevState) => ({
            ...prevState,
            ["project_id"]: value,
            ["address"]: data.address,
            ["contact_person"]: data.contact_person,
            ["phone_no"]: data.phone_no,
          }));
        });
    } else if (e.target.name === "customer_id") {
      const { name, value } = e.target;
      customerInfo
        .filter((info) => info.value === value)
        .map((data) => {
          setFranchiseeInvoice((prevState) => ({
            ...prevState,
            ["customer_id"]: value,
            ["email"]: data.email,
            ["contact_number"]: data.contact_number,
          }));
        });
      // } else if (e.target.name === "beginning_credit_limit") {
    } else if (typeof e === "string") {
      const { name, value } = e.target;
      if (add) {
        setFranchiseeInvoice((prevState) => ({
          ...prevState,
          [name]: value,
        }));
      } else {
        setFranchiseeInvoice((prevState) => ({
          ...prevState,
          [name]: value,
        }));
      }
    } else if (typeof e === "string") {
      setFranchiseeInvoice((prevState) => ({
        ...prevState,
        ["name"]: e,
      }));
    } else {
      const { name, value } = e.target;
      setFranchiseeInvoice((prevState) => ({
        ...prevState,
        [name]: value,
      }));
    }
  };

  const [name, setName] = useState("");

  const handleFranchiseChange = (e) => {
    if (e[0] !== undefined) {
      if (e[0].customOption ? e[0].customOption : false) {
        setFranchiseeInvoice((prevState) => ({
          ...prevState,
          ["name"]: e[0].name,
          ["email"]: "",
        }));
        setFranchiseeName([e[0]]);
        setName(e[0].name);
      } else {
        setFranchiseeInvoice((prevState) => ({
          ...prevState,
          ["name"]: e[0].name,
          ["email"]: e[0].email,
        }));
        setFranchiseeName([e[0]]);
      }
    } else if (e === undefined || e === null || e.length === 0) {
      setFranchiseeName([]);
      if (e[0]?.name) {
        setFranchiseeInvoice((prevState) => ({
          ...prevState,
          ["name"]: e[0].name,
          ["email"]: "",
        }));
        setFranchiseeName([e[0]]);
      }
    }
  };

  //USE EFFECTS
  useEffect(() => {
    if (pi_id) {
      fetchFranchisee();
    }

    fetchBranches();
    fetchCustomer();
    fetchBanks();
    fetchPriceLevel();
    fetchAllFranchisees();
  }, []);

  React.useEffect(() => {
    if (addTo !== "") {
      handleCloseAddModal();
      handleShowAddBranchModal();
      fetchBranchGroup();
      fetchInventoryGroup();
      fetchPriceLevel();
    }
  }, [addTo]);

  useEffect(() => {
    setGrandTotal(
      parseFloat(
        franchiseeInvoice.franchisee_fee ? franchiseeInvoice.franchisee_fee : 0
      ) +
        parseFloat(
          franchiseeInvoice.security_deposit
            ? franchiseeInvoice.security_deposit
            : 0
        ) +
        parseFloat(franchiseeInvoice.taxes ? franchiseeInvoice.taxes : 0) +
        parseFloat(
          franchiseeInvoice.other_fee ? franchiseeInvoice.other_fee : 0
        ) +
        parseFloat(
          franchiseeInvoice.franchisee_package
            ? franchiseeInvoice.franchisee_package
            : 0
        )
    );
  }, [
    franchiseeInvoice.royalty_fee,
    franchiseeInvoice.marketing_fee,
    franchiseeInvoice.franchisee_fee,
    franchiseeInvoice.franchisee_package,
    franchiseeInvoice.security_deposit,
    franchiseeInvoice.taxes,
    franchiseeInvoice.other_fee,
  ]);

  async function create() {
    if (validateBranches(addBranchData, setIsError)) {
      setIsClicked(true);
      const response = await createProject(addBranchData);
      if (response) {
        if (response?.data?.status === "success") {
          toast.success("Successfully added project!", {
            style: toastStyle(),
          });
          setTimeout(() => refreshPage(), 1000);
        }
        if (response?.error?.data?.messages?.name) {
          toast.error(response.error.data.messages.name, {
            style: toastStyle(),
          });
        }
        if (response?.error?.data?.messages?.account_name) {
          toast.error(response.error.data.messages.account_name, {
            style: toastStyle(),
          });
        }
      }
    }
  }

  return (
    <div>
      <div className="page">
        <Navbar
          onCollapse={(inactive) => {
            setInactive(inactive);
          }}
          active={"FRANCHISE"}
        />
      </div>

      <div className={`container ${inactive ? "inactive" : "active"}`}>
        {/* header */}
        <div className="d-flex justify-content-between align-items-center my-3 pb-4">
          <h1 className="page-title mb-0">{add ? "ADD " : "EDIT "}PROJECT</h1>
        </div>

        {/* content */}
        <div className="edit-form">
          {/* FRANCHISEE SALES INVOICE DETAILS */}
          <Fragment>
            <Row className="pt-3 mb-2">
              <Col xs={5}>
                <span className="edit-label">
                  Project Name
                  <label className="badge-required">{` *`}</label>
                </span>
              </Col>
              <Col xs={3}>
                <span className="edit-label">
                  Project Date
                  <label className="badge-required">{` *`}</label>
                </span>
              </Col>
              <Col>
                <span className="edit-label">
                  Start Date
                  <label className="badge-required">{` *`}</label>
                </span>
              </Col>
            </Row>
            <Row>
              <Col xs={5}>
                <Form.Select
                  type="select"
                  className="react-select-container"
                  classNamePrefix="react-select"
                  placeholder="Select project..."
                  name="project_id"
                  value={franchiseeInvoice.project_id}
                  onChange={(e) => handleChange(e, true)}
                >
                  <option value="">Select a Project...</option>
                  {franchiseeBranches.map((data) => {
                    return (
                      <option key={data.value} value={data.value}>
                        {data.label}
                      </option>
                    );
                  })}
                </Form.Select>
                <InputError
                  isValid={isError.project_id}
                  message={"Project is required"}
                />

                {/* <Col>
                  <div className="d-flex justify-content-end">
                    <span className="edit-label" style={{ color: "#df1227" }}>
                      Project Not Found?{" "}
                      <a
                        onClick={handleShowAddBranchModal}
                        className="add-supplier-label"
                      >
                        Click here to add project
                      </a>
                    </span>
                  </div>
                </Col> */}
              </Col>
              <Col xs={3}>
                <Form.Control
                  type="date"
                  name="franchised_on"
                  className="nc-modal-custom-text"
                  value={franchiseeInvoice.franchised_on}
                  defaultValue={today}
                  onChange={(e) => handleChange(e, false)}
                />
                <InputError
                  isValid={isError.franchised_on}
                  message={"Project date is required"}
                />
              </Col>
              <Col>
                <Form.Control
                  type="date"
                  name="opening_start"
                  className="nc-modal-custom-text"
                  value={franchiseeInvoice.opening_start}
                  onChange={(e) => handleChange(e)}
                />
                <InputError
                  isValid={isError.opening_start}
                  message={"Start date is required"}
                />
              </Col>
            </Row>
            <Row className="mt-4 mb-2">
              <Col>
                <span className="edit-label">Address</span>
              </Col>
              <Col>
                <span className="edit-label">
                  Contact Person
                  <label className="badge-required">{` *`}</label>
                </span>
              </Col>
              <Col>
                <span className="edit-label">
                  Contact Number
                  <label className="badge-required">{` *`}</label>
                </span>
              </Col>
            </Row>
            <Row>
              <Col>
                <Form.Control
                  type="text"
                  name="address"
                  className="nc-modal-custom-text"
                  value={franchiseeInvoice.address}
                  onChange={(e) => handleChange(e)}
                />
              </Col>
              <Col>
                <Form.Control
                  type="text"
                  name="contact_person"
                  value={franchiseeInvoice.contact_person}
                  className="nc-modal-custom-text"
                  onChange={(e) => handleChange(e, false)}
                />
              </Col>
              <Col>
                <Form.Control
                  type="text"
                  name="phone_no"
                  className="nc-modal-custom-text"
                  value={franchiseeInvoice.phone_no}
                  onChange={(e) => handleChange(e)}
                />
              </Col>
            </Row>
            <Row className="mt-4 mb-2">
              <Col>
                <span className="edit-label">
                  Customer Name
                  <label className="badge-required">{` *`}</label>
                </span>
              </Col>
              <Col>
                <span className="edit-label">Customer Contact Number</span>
              </Col>
              <Col>
                <span className="edit-label">Customer Email</span>
              </Col>
            </Row>
            <Row className="mt-4 mb-2">
              <Col>
                <Form.Select
                  type="select"
                  className="react-select-container"
                  classNamePrefix="react-select"
                  placeholder="Select Customer..."
                  name="customer_id"
                  value={franchiseeInvoice.customer_id}
                  onChange={(e) => handleChange(e, false)}
                >
                  <option value="">Select a Customer...</option>
                  {customersData.map((data) => {
                    return (
                      <option key={data.value} value={data.value}>
                        {data.label}
                      </option>
                    );
                  })}
                </Form.Select>
                <InputError
                  isValid={isError.customer}
                  message={"Customer name is required"}
                />
              </Col>
              <Col>
                <Form.Control
                  type="text"
                  name="contact_number"
                  className="nc-modal-custom-text"
                  value={franchiseeInvoice.contact_number}
                  onChange={(e) => handleChange(e)}
                />
                <InputError
                  isValid={isError.contact_number}
                  message={"Contact person is required"}
                />
              </Col>
              <Col>
                <Form.Control
                  type="text"
                  name="email"
                  className="nc-modal-custom-text"
                  value={franchiseeInvoice.email}
                  onChange={(e) => handleChange(e)}
                />
                <InputError
                  isValid={isError.email}
                  message={"Email is required"}
                />
              </Col>
            </Row>
          </Fragment>
          <Row className="align-right pt-3 mt-5">
            <Col xs={2} className="text-end">
              <span className="edit-label color-gray">
                Project Type
                <label className="badge-required">{` *`}</label>
              </span>
            </Col>
            <Col xs={1} className="text-end"></Col>
            <Col xs={3}>
              <Form.Control
                type="text"
                name="package_type"
                value={franchiseeInvoice.package_type}
                className="align-middle nc-modal-custom-text"
                onChange={(e) => handleChange(e)}
              />
              <InputError
                isValid={isError.package_type}
                message={"Project Type is required"}
              />
            </Col>
          </Row>
          <Row className="align-right pt-3">
            <Col xs={2} className="text-end">
              <span className="edit-label color-gray">Project Price</span>
            </Col>
            <Col xs={1} className="text-end">
              <span className="edit-label align-middle">PHP</span>
            </Col>
            <Col xs={3}>
              <Form.Control
                type="number"
                name="security_deposit"
                min={0}
                step="0.01"
                value={franchiseeInvoice.security_deposit}
                className="align-middle nc-modal-custom-text"
                onChange={(e) => handleChange(e)}
              />
            </Col>
          </Row>
          <Row className="align-right pt-3">
            <Col xs={2} className="text-end">
              <span className="edit-label color-gray">Taxes</span>
            </Col>
            <Col xs={1} className="text-end">
              <span className="edit-label align-middle">PHP</span>
            </Col>
            <Col xs={3}>
              <Form.Control
                type="number"
                name="taxes"
                min={0}
                step="0.01"
                value={franchiseeInvoice.taxes}
                className="align-middle nc-modal-custom-text"
                onChange={(e) => handleChange(e)}
              />
              <InputError
                isValid={isError.taxes}
                message={"Taxes is required"}
              />
            </Col>
          </Row>
          <Row className="align-right pt-3">
            <Col xs={2} className="text-end">
              <span className="edit-label color-gray">Other Fees</span>
            </Col>
            <Col xs={1} className="text-end">
              <span className="edit-label align-middle">PHP</span>
            </Col>
            <Col xs={3}>
              <Form.Control
                type="number"
                name="other_fee"
                min={0}
                step="0.01"
                value={franchiseeInvoice.other_fee}
                className="align-middle nc-modal-custom-text"
                onChange={(e) => handleChange(e)}
              />
              <InputError
                isValid={isError.other_fee}
                message={"Other fee is required"}
              />
            </Col>
          </Row>
          <Row className="align-right py-5">
            <Col xs={2} className="text-end">
              <span className="edit-label color-gray grand-total-text">
                Grand Total
              </span>
            </Col>
            <Col xs={1} className="text-end">
              <span className="edit-label align-middle grand-total-text">
                PHP
              </span>
            </Col>
            <Col xs={3} className="text-end">
              <span className="edit-label align-middle grand-total-text">
                {franchiseeInvoice.paid_amount
                  ? numberFormat(
                      grandTotal
                      // - parseFloat(
                      //       franchiseeInvoice.paid_amount
                      //   )
                    )
                  : numberFormat(grandTotal)}
              </span>
            </Col>
          </Row>
          {franchiseeInvoice.payment_method === "cash" && (
            <>
              <div className="mt-5"></div>
              <hr />
              <div className="payment-header-wrapper mb-5">
                <h5 className="payment-header">Payment Details</h5>
              </div>
              <Row className="mt-4 mb-2">
                <Col>
                  <span className="edit-label">
                    Payment Date
                    <span className="color-red"> *</span>
                  </span>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Form.Control
                    type="date"
                    name="payment_date"
                    className="nc-modal-custom-text"
                    value={franchiseeInvoice.payment_date}
                    onChange={(e) => handleChange(e)}
                  />
                  <InputError
                    isValid={isError.payment_date}
                    message={"Payment date is required"}
                  />
                </Col>
              </Row>
              <Row className="mt-4 mb-2">
                <Col>
                  <span className="edit-label">
                    Invoice Number
                    <label className="badge-required">{` *`}</label>
                  </span>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Form.Control
                    type="text"
                    name="invoice_no"
                    className="nc-modal-custom-text"
                    value={franchiseeInvoice.invoice_no}
                    onChange={(e) => handleChange(e)}
                  />
                  <InputError
                    isValid={isError.invoice_no}
                    message={"Invoice Number is required"}
                  />
                </Col>
              </Row>
              <Row className="mt-4 mb-2">
                <Col>
                  <span className="edit-label">
                    Deposit Date
                    <span className="color-red"> *</span>
                  </span>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Form.Control
                    type="date"
                    name="deposit_date"
                    className="nc-modal-custom-text"
                    defaultValue={franchiseeInvoice.deposit_date}
                    onChange={(e) => handleChange(e)}
                  />
                </Col>
              </Row>
              <Row className="mt-4 mb-2">
                <Col>
                  <span className="edit-label">
                    Deposited to
                    <span className="edit-optional px-2"></span>
                  </span>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Form.Select
                    type="text"
                    name="to_bank_id"
                    className="nc-modal-custom-text"
                    value={franchiseeInvoice.to_bank_id}
                    onChange={(e) => handleChange(e)}
                  >
                    <option value="">Select a bank...</option>
                    {banks.map((data) => {
                      return <option value={data.id}>{data.bank_name}</option>;
                    })}
                  </Form.Select>
                </Col>
              </Row>
              <Row className="mt-4 mb-2">
                <Col>
                  <span className="edit-label">
                    Term (days)
                    <span className="edit-optional px-2">(Optional)</span>
                  </span>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Form.Control
                    type="text"
                    name="term_days"
                    className="nc-modal-custom-text"
                    value={franchiseeInvoice.term_days}
                    onChange={(e) => handleChange(e)}
                  />
                </Col>
              </Row>
              <Row className="mt-4 mb-2">
                <Col>
                  <span className="edit-label">
                    Remarks
                    <span className="edit-optional px-2">(Optional)</span>
                  </span>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Form.Control
                    as="textarea"
                    name="remarks"
                    value={franchiseeInvoice.remarks}
                    className="nc-modal-custom-text"
                    onChange={(e) => handleChange(e)}
                  />
                </Col>
              </Row>
            </>
          )}

          {/* CHECK PAYMENT DETAILS */}
          {franchiseeInvoice.payment_method === "check" && (
            <>
              <div className="mt-5"></div>
              <hr />
              <div className="payment-header-wrapper mb-5">
                <h5 className="payment-header">Payment Details</h5>
              </div>
              <Row className="mt-4 mb-2">
                <Col>
                  <span className="edit-label">
                    Payment Date
                    <label className="badge-required">{` *`}</label>
                  </span>
                </Col>
                <Col>
                  <span className="edit-label">
                    Invoice Number
                    <label className="badge-required">{` *`}</label>
                  </span>
                </Col>
                <Col>
                  <span className="edit-label">
                    Check Date
                    <label className="badge-required">{` *`}</label>
                  </span>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Form.Control
                    type="date"
                    name="payment_date"
                    className="nc-modal-custom-text"
                    defaultValue={franchiseeInvoice.payment_date}
                    onChange={(e) => handleChange(e)}
                  />
                  <InputError
                    isValid={isError.payment_date}
                    message={"Payment date is required"}
                  />
                </Col>
                <Col>
                  <Form.Control
                    type="text"
                    name="invoice_no"
                    className="nc-modal-custom-text"
                    value={franchiseeInvoice.invoice_no}
                    onChange={(e) => handleChange(e)}
                  />
                  <InputError
                    isValid={isError.invoice_no}
                    message={"Invoice Number is required"}
                  />
                </Col>
                <Col>
                  <Form.Control
                    type="date"
                    name="cheque_date"
                    className="nc-modal-custom-text"
                    value={franchiseeInvoice.cheque_date}
                    onChange={(e) => handleChange(e)}
                  />
                  <InputError
                    isValid={isError.cheque_date}
                    message={"Check date is required"}
                  />
                </Col>
              </Row>

              <Row className="mt-4 mb-2">
                <Col>
                  <span className="edit-label">
                    Bank Name
                    <label className="badge-required">{` *`}</label>
                  </span>
                </Col>
                <Col>
                  <span className="edit-label">
                    Check Number
                    <label className="badge-required">{` *`}</label>
                  </span>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Form.Control
                    type="text"
                    name="bank_name"
                    className="nc-modal-custom-text"
                    value={franchiseeInvoice.bank_name}
                    onChange={(e) => handleChange(e)}
                  />
                  <InputError
                    isValid={isError.bank_name}
                    message={"Bank name is required"}
                  />
                </Col>
                <Col>
                  <Form.Control
                    type="text"
                    name="cheque_number"
                    className="nc-modal-custom-text"
                    value={franchiseeInvoice.cheque_number}
                    onChange={(e) => handleChange(e)}
                  />
                  <InputError
                    isValid={isError.cheque_number}
                    message={"Cheque number is required"}
                  />
                </Col>
              </Row>
              <Row className="mt-4 mb-2">
                <Col>
                  <span className="edit-label">
                    Deposit Date
                    <span className="color-red"> *</span>
                  </span>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Form.Control
                    type="date"
                    name="deposit_date"
                    className="nc-modal-custom-text"
                    defaultValue={franchiseeInvoice.deposit_date}
                    onChange={(e) => handleChange(e)}
                  />
                </Col>
              </Row>
              <Row className="mt-4 mb-2">
                <Col>
                  <span className="edit-label">
                    Deposited to
                    <span className="edit-optional px-2"></span>
                  </span>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Form.Select
                    type="text"
                    name="to_bank_id"
                    className="nc-modal-custom-text"
                    value={franchiseeInvoice.to_bank_id}
                    onChange={(e) => handleChange(e)}
                  >
                    <option value="">Select a bank...</option>
                    {banks.map((data) => {
                      return <option value={data.id}>{data.bank_name}</option>;
                    })}
                  </Form.Select>
                </Col>
              </Row>
              <Row className="mt-4 mb-2">
                <Col>
                  <span className="edit-label">
                    Term (days)
                    <span className="edit-optional px-2">(Optional)</span>
                  </span>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Form.Control
                    type="text"
                    name="term_days"
                    className="nc-modal-custom-text"
                    value={franchiseeInvoice.term_days}
                    onChange={(e) => handleChange(e)}
                  />
                </Col>
              </Row>
              <Row className="mt-4 mb-2">
                <Col>
                  <span className="edit-label">
                    Remarks
                    <span className="edit-optional px-2">(Optional)</span>
                  </span>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Form.Control
                    as="textarea"
                    name="remarks"
                    value={franchiseeInvoice.remarks}
                    className="nc-modal-custom-text"
                    onChange={(e) => handleChange(e)}
                  />
                </Col>
              </Row>
            </>
          )}

          {/* OTHERS PAYMENT DETAILS */}
          {franchiseeInvoice.payment_method === "others" && (
            <>
              <div className="mt-5"></div>
              <hr />
              <div className="payment-header-wrapper mb-5">
                <h5 className="payment-header">Payment Details</h5>
              </div>
              <Row className="mt-4 mb-2">
                <Col>
                  <span className="edit-label">Payment Date</span>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Form.Control
                    type="date"
                    name="payment_date"
                    className="nc-modal-custom-text"
                    defaultValue={franchiseeInvoice.payment_date}
                    onChange={(e) => handleChange(e)}
                  />
                </Col>
              </Row>
              <Row className="mt-4 mb-2">
                <Col>
                  <span className="edit-label">
                    Invoice Number
                    <label className="badge-required">{` *`}</label>
                  </span>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Form.Control
                    type="text"
                    name="invoice_no"
                    className="nc-modal-custom-text"
                    value={franchiseeInvoice.invoice_no}
                    onChange={(e) => handleChange(e)}
                  />
                  <InputError
                    isValid={isError.invoice_no}
                    message={"Invoice Number is required"}
                  />
                </Col>
              </Row>
              <Row className="mt-4 mb-2">
                <Col>
                  <span className="edit-label">
                    Reference Number
                    <label className="badge-required">{` *`}</label>
                  </span>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Form.Control
                    type="text"
                    name="reference_number"
                    className="nc-modal-custom-text"
                    value={franchiseeInvoice.reference_number}
                    onChange={(e) => handleChange(e)}
                  />
                  <InputError
                    isValid={isError.reference_number}
                    message={"Reference number is required"}
                  />
                </Col>
              </Row>
              <Row className="mt-4 mb-2">
                <Col>
                  <span className="edit-label">
                    Deposit Date
                    <span className="color-red"> *</span>
                  </span>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Form.Control
                    type="date"
                    name="deposit_date"
                    className="nc-modal-custom-text"
                    defaultValue={franchiseeInvoice.deposit_date}
                    onChange={(e) => handleChange(e)}
                  />
                </Col>
              </Row>
              <Row className="mt-4 mb-2">
                <Col>
                  <span className="edit-label">
                    Deposited to
                    <span className="edit-optional px-2"></span>
                  </span>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Form.Select
                    type="text"
                    name="to_bank_id"
                    className="nc-modal-custom-text"
                    value={franchiseeInvoice.to_bank_id}
                    onChange={(e) => handleChange(e)}
                  >
                    <option value="">Select a bank...</option>
                    {banks.map((data) => {
                      return <option value={data.id}>{data.bank_name}</option>;
                    })}
                  </Form.Select>
                </Col>
              </Row>
              <Row className="mt-4 mb-2">
                <Col>
                  <span className="edit-label">
                    Term (days)
                    <span className="edit-optional px-2">(Optional)</span>
                  </span>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Form.Control
                    type="text"
                    name="term_days"
                    className="nc-modal-custom-text"
                    value={franchiseeInvoice.term_days}
                    onChange={(e) => handleChange(e)}
                  />
                </Col>
              </Row>
              <Row className="mt-4 mb-2">
                <Col>
                  <span className="edit-label">
                    Remarks
                    <span className="edit-optional px-2">(Optional)</span>
                  </span>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Form.Control
                    as="textarea"
                    name="remarks"
                    value={franchiseeInvoice.remarks}
                    className="nc-modal-custom-text"
                    onChange={(e) => handleChange(e)}
                  />
                </Col>
              </Row>
            </>
          )}

          {/* FOOTER: CANCEL & SUBMIT BUTTONS */}
          <div className="d-flex justify-content-end pt-5 pb-3">
            <button
              type="button"
              className="button-secondary me-3"
              onClick={() => navigate(-1)}
            >
              Cancel
            </button>
            {isSubmitClicked ? (
              <div className="button-primary d-flex justify-content-center">
                <ReactLoading
                  type="bubbles"
                  color="#FFFFFF"
                  height={50}
                  width={50}
                />
              </div>
            ) : (
              <button
                type="button"
                className="button-primary"
                onClick={handleSubmit}
              >
                Submit
              </button>
            )}
          </div>
        </div>
      </div>
      <AddModal
        title="PROJECT"
        size="lg"
        type="branch"
        show={showAddBranchModal}
        onHide={handleCloseAddBranchModal}
        onSave={() => create()}
        isClicked={isClicked}
      >
        <div className="mt-3">
          <Row className="nc-modal-custom-row mt-0">
            <Col>
              PROJECT NAME
              <span className="required-icon">*</span>
              <Form.Control
                type="text"
                name="name"
                value={addBranchData.name}
                className="nc-modal-custom-input"
                onChange={(e) => handleAddChange(e)}
                required
              />
              <InputError
                isValid={isError.name}
                message={"project name is required"}
              />
            </Col>
            <Col>
              COMPANY
              <span className="required-icon">*</span>
              <Form.Control
                type="text"
                name="company"
                value={addBranchData.company}
                className="nc-modal-custom-input"
                onChange={(e) => handleAddChange(e)}
                required
              />
              <InputError
                isValid={isError.company}
                message={"Company name is required"}
              />
            </Col>
            <Col>
              ADDRESS
              <span className="required-icon">*</span>
              <Form.Control
                type="text"
                name="address"
                value={addBranchData.address}
                className="nc-modal-custom-input"
                onChange={(e) => handleAddChange(e)}
                required
              />
              <InputError
                isValid={isError.address}
                message={"Address is required"}
              />
            </Col>
          </Row>
          <Row className="nc-modal-custom-row mt-0">
            <Col>
              CONTACT NUMBER
              <Form.Control
                type="text"
                name="phone_no"
                value={addBranchData.phone_no}
                className="nc-modal-custom-input"
                onChange={(e) => handleAddChange(e)}
                required
              />
            </Col>
            <Col>
              TIN NUMBER
              <span className="required-icon">*</span>
              <Form.Control
                type="text"
                name="tin_no"
                value={addBranchData.tin_no}
                className="nc-modal-custom-input"
                onChange={(e) => handleAddChange(e)}
                required
              />
              <InputError
                isValid={isError.tin_no}
                message={"Contact person is required"}
              />
            </Col>
            <Col>
              CONTACT PERSON
              <span className="required-icon">*</span>
              <Form.Control
                type="text"
                name="contact_person"
                value={addBranchData.contact_person}
                className="nc-modal-custom-input"
                onChange={(e) => handleAddChange(e)}
                required
              />
              <InputError
                isValid={isError.contact_person}
                message={"Contact person is required"}
              />
            </Col>
          </Row>
        </div>
      </AddModal>
    </div>
  );
}

FormFranchiseInvoice.defaultProps = {
  add: false,
  edit: false,
};

export default FormFranchiseInvoice;
