import { useEffect } from "react";
import { useState } from "react";
import { Modal, Col, Row, Form } from "react-bootstrap";
import toast from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import Select from "react-select";
import { getAllBanks } from "../../Helpers/apiCalls/banksAPi";
import {
  getTodayDateISO,
  refreshPage,
  toastStyle,
  formatAmount
} from "../../Helpers/Utils/Common";
import { validatePayment } from "../../Helpers/Validation/Franchise/FranchiseModalValidation";
import InputError from "../../Components/InputError/InputError";
import ReactLoading from "react-loading";
import { payProjectInvoiceModal } from "../../Helpers/apiCalls/ProjectInvoice/ProjectInvoiceApi.js";


export default function AddPaymentModal({
  id,
  projectId,
  show,
  onHide,
  balance,
  invoice,
}) {
  const navigate = useNavigate();
  const [bankTo, setBankTo] = useState([]);

  const [depositValue, setDepositValue] = useState({
    name: "to_bank_id",
    label: "",
    value: "",
  });

  const [isError, setIsError] = useState({
    payment_date: false,
    payment_type: false,
    paid_amount: false,
    deposit_date: false,
    to_bank_id: false,
    bank_name: false,
    check_date: false,
    check_number: false,
    reference_number: false,
  });

  const [isClicked, setIsClicked] = useState(false);

  const [addDetails, setAddDetails] = useState({
    payment_date: getTodayDateISO(),
    deposit_date: getTodayDateISO(),
    payment_type: "cash",
    paid_amount: balance,
    invoice_no: id,
    check_date: "",
    check_number: "",
    bank_name: "",
    payment_description: "",
    paid_amount: ""
  });
  function handleAddDetailsChange(e) {
    const { name, value } = e.target;
    if (name === "to_bank_id") {
      setDepositValue(() => {
        return { name: name, value: value, label: e.label };
      });
    }
    setAddDetails((prev) => ({ ...prev, [name]: value }));
  }

  
  async function handleCreatePayment() {
    
    addDetails.invoice_no = id;
    if (validatePayment(addDetails, setIsError)) {
      setIsClicked(true);
      const response = await payProjectInvoiceModal(
          id,
          projectId,
          addDetails,
          balance
      );
      if (response.data) {
          if (response.data.status === "success") {
          toast.success("Payment saved!", {
              style: toastStyle(),
          });
          setTimeout(() => navigate("/projectinvoice/print/" + id), 1000);
        }
      } else if (response.error) {
          var errMsg = response.error.response.data.messages.error;
          toast.error(errMsg, { style: toastStyle() });
      }
      setIsClicked(false);
    }
  }

  useEffect(() => {
    async function fetchBanks() {
      const response = await getAllBanks();
      var banks = response.data.data;
  
      var cleanedArray2 = banks.map((bank) => {
        var info = {};
        info.name = "to_bank_id";
        info.label = bank.bank_name;
        info.value = bank.id;
  
        return info;
      });
      setBankTo(cleanedArray2);
    }
    fetchBanks();
  }, []);

  return (
    <div>
      <Modal show={show} onHide={() => {onHide(); setAddDetails({})}} size="lg" centered>
        <Modal.Header closeButton>
          <span className="page-title"> Add Payment</span>
        </Modal.Header>
        <Modal.Body className="return-body">
          <div>
            <Row>
              <Col xs={6}>
                <span className="edit-label">
                  Payment Date<span className="color-red"> *</span>
                </span>
                <Form.Control
                  type="date"
                  name="payment_date"
                  className="nc-modal-custom-text"
                  defaultValue={addDetails.payment_date}
                  onChange={(e) => handleAddDetailsChange(e)}
                />
                <InputError
                  isValid={isError.payment_date}
                  message={"Payment date is required"}
                />
              </Col>
              <Col xs={6}>
                <span className="edit-label">
                  Invoice Number
                  <label className="badge-required">{` *`}</label>
                </span>
                <Form.Control
                  type="text"
                  name="invoice_no"
                  className="nc-modal-custom-text"
                  defaultValue={id}
                  disabled
                />
                {/* <InputError
                  isValid={isError.invoice_no}
                  message={"Invoice number is required"}
                /> */}
              </Col>
            </Row>
            <Row className="mt-2">
              <Col>
                <span className="edit-label">Payment Method</span>
              </Col>
            </Row>
            <Row>
              <Col>
                <Form.Check
                  inline
                  label="Cash"
                  name="payment_type"
                  value="cash"
                  type="radio"
                  defaultChecked
                  onClick={(e) => {
                    handleAddDetailsChange(e);
                  }}
                />
                <Form.Check
                  inline
                  label="Check"
                  name="payment_type"
                  type="radio"
                  value="check"
                  onClick={(e) => {
                    handleAddDetailsChange(e);
                  }}
                />
                <Form.Check
                  inline
                  label="Others"
                  name="payment_type"
                  value="others"
                  type="radio"
                  onClick={(e) => {
                    handleAddDetailsChange(e);
                  }}
                />
              </Col>
              <InputError
                isValid={isError.payment_type}
                message={"Payment type is required"}
              />
              <Col>
                <span className="">Balance: {balance?formatAmount(balance):''}</span>
              </Col>
            </Row>
          </div>

          <Row className="mt-4">
            <Col xs={6}>
              <span className="edit-label">
                Paid Amount
                <span className="color-red"> *</span>
              </span>
              <Form.Control
                type="text"
                name="paid_amount"
                className="nc-modal-custom-text"
                defaultValue={balance}
                // value={balance}
                onChange={(e) => handleAddDetailsChange(e)}
              />
              <InputError
                isValid={isError.paid_amount}
                message={"paid amount is required"}
              />
            </Col>
            <Col xs={6}>
              <span className="edit-label">
                Term (days)
                <span className="edit-optional px-2">(Optional)</span>
              </span>
              <Form.Control
                type="text"
                name="term_days"
                className="nc-modal-custom-text"
                value={addDetails.term_days}
                onChange={(e) => handleAddDetailsChange(e)}
              />
            </Col>
          </Row>

          <Row className="mt-3">
            <Col xs={6}>
              <span className="edit-label">
                Deposit Date
                <span className="color-red"> *</span>
              </span>
              <Form.Control
                type="date"
                name="deposit_date"
                className="nc-modal-custom-text"
                defaultValue={addDetails.deposit_date}
                onChange={(e) => handleAddDetailsChange(e)}
              />
              <InputError
                isValid={isError.deposit_date}
                message={"paid amount is required"}
              />
            </Col>
            <Col xs={6}>
              <span className="edit-label">
                Deposited to
                <span className="color-red"> *</span>
              </span>
              <Select
                name="to_bank_id"
                className="react-select-container"
                classNamePrefix="react-select"
                placeholder="Select bank..."
                defaultValue={depositValue}
                options={bankTo}
                onChange={(e) =>
                  handleAddDetailsChange({
                    target: {
                      name: e.name,
                      value: e.value,
                      label: e.label,
                    },
                  })
                }
              />
              <InputError
                isValid={isError.to_bank_id}
                message={"Deposited to is required is required"}
              />
            </Col>
          </Row>

          {/* CHECK PAYMENT DETAILS */}
          {addDetails.payment_type === "check" && (
            <>
              <Row className="mt-3">
                <Col xs={4}>
                  <span className="edit-label">Bank Name<span className="color-red"> *</span></span>
                  <Form.Control
                    type="text"
                    name="bank_name"
                    className="nc-modal-custom-text"
                    value={addDetails.bank_name}
                    onChange={(e) => handleAddDetailsChange(e)}
                  />
                  <InputError
                    isValid={isError.bank_name}
                    message={"Bank name is required"}
                  />
                </Col>
                <Col xs={4}>
                  <span className="edit-label">Check Date<span className="color-red"> *</span></span>
                  <Form.Control
                    type="date"
                    name="check_date"
                    className="nc-modal-custom-text"
                    defaultValue={addDetails.check_date}
                    onChange={(e) => handleAddDetailsChange(e)}
                  />
                  <InputError
                    isValid={isError.check_date}
                    message={"Check date is required"}
                  />
                </Col>
                <Col xs={4}>
                  <span className="edit-label">Check Number<span className="color-red"> *</span></span>
                  <Form.Control
                    type="text"
                    name="check_number"
                    className="nc-modal-custom-text"
                    defaultValue={addDetails.check_number}
                    onChange={(e) => handleAddDetailsChange(e)}
                  />
                  <InputError
                    isValid={isError.check_number}
                    message={"Check number is required"}
                  />
                </Col>
              </Row>
            </>
          )}

          {/* OTHERS PAYMENT DETAILS */}
          {addDetails.payment_type === "others" && (
            <>
              <Row className="mt-3">
                <Col xs={6}>
                  <span className="edit-label">Payment Description<label className="badge-required">{` *`}</label></span>
                  <Form.Control
                    type="text"
                    name="payment_description"
                    className="nc-modal-custom-text"
                    defaultValue={addDetails.payment_description}
                    onChange={(e) => handleAddDetailsChange(e)}
                  />
                  <InputError isValid={isError.payment_description} message={"Payment description is required"}/>
                </Col>
                <Col xs={6}>
                  <span className="edit-label">
                    Reference Number
                    <label className="badge-required">{` *`}</label>
                  </span>
                  <Form.Control
                    type="text"
                    name="reference_number"
                    className="nc-modal-custom-text"
                    value={addDetails.reference_number}
                    onChange={(e) => handleAddDetailsChange(e)}
                  />
                  <InputError
                    isValid={isError.reference_number}
                    message={"Reference number is required"}
                  />
                </Col>
              </Row>
            </>
          )}

          <Row className="mt-3">
            <Col>
              <span className="edit-label">
                Remarks
                <span className="edit-optional px-2">(Optional)</span>
              </span>
              <Form.Control
                as="textarea"
                name="remarks"
                value={setAddDetails.remarks}
                className="nc-modal-custom-text"
                onChange={(e) => handleAddDetailsChange(e)}
              />
            </Col>
          </Row>
        </Modal.Body>
        <Modal.Footer className="return-footer">
          <button type="button" className="button-secondary" onClick={() => {onHide(); setAddDetails({})}}>
            Cancel
          </button>
          {isClicked ? (
            <div className="button-primary d-flex justify-content-center">
              <ReactLoading
                type="bubbles"
                color="#FFFFFF"
                height={50}
                width={50}
              />
            </div>
          ) : (
            <button
              type="button"
              className="button-primary"
              onClick={() => handleCreatePayment()}
            >
              Pay
            </button>
          )}
        </Modal.Footer>
      </Modal>
    </div>
  );
}
