import { handleValidationChange } from "../CommonValidation";

export const validateAddSO = (data, setIsError) => {
  var isValid = true;
  var isValidType = true;

  if (data.supplier_id === "" && data.vendor_id === "") {
    handleValidationChange("supplierName", true, setIsError);
    isValid = false;
  } else {
    handleValidationChange("supplierName", false, setIsError);
    isValid = true;
  }

  if (data.requisitioner === "" || data.requisitioner === undefined) {
    handleValidationChange("requisitioner", true, setIsError);
    isValid = false;
  } else {
    handleValidationChange("requisitioner", false, setIsError);
    isValid = true;
  }

  if (data.type === "" || data.type === undefined) {
    handleValidationChange("type", true, setIsError);
    isValid = false;
  } else {
    handleValidationChange("type", false, setIsError);
    isValid = true;
  }

  if (
    data.supplies_expense_date === "" ||
    data.supplies_expense_date === undefined ||
    data.supplies_expense_date === "Invalid Date"
  ) {
    handleValidationChange("purchaseDate", true, setIsError);
    isValid = false;
  } else {
    handleValidationChange("purchaseDate", false, setIsError);
    isValid = true;
  }

  if (
    data.delivery_date === "" ||
    data.delivery_date === undefined ||
    data.purchase_date === "Invalid Date"
  ) {
    handleValidationChange("deliveryDate", true, setIsError);
    isValid = false;
  } else {
    handleValidationChange("deliveryDate", false, setIsError);
    isValid = true;
  }

  if (data.requisitioner === "" || data.requisitioner === undefined) {
    handleValidationChange("requisitioner", true, setIsError);
    isValid = false;
  } else {
    handleValidationChange("requisitioner", false, setIsError);
    isValid = true;
  }

  // if (data.delivery_address === "" || data.delivery_address === undefined) {
  //   handleValidationChange("deliveryAddress", true, setIsError);
  //   isValid = false;
  // } else {
  //   handleValidationChange("deliveryAddress", false, setIsError);
  //   isValid = true;
  // }

  return isValid;
};

