import {
    formatDateNoTime,
    formatYDM,
    getToken,
    getToken2,
    getUser,
    numberFormatInt
} from "../../Utils/Common";
import Moment from "moment";
import { getAPICall, postAPICall } from "../axiosMethodCalls";

export const searchPayablesAgingReport = async (data) => {
    try {
        const response = await getAPICall(
            process.env.REACT_APP_LINK + "reports/get_payables_aging",
            {
                requester: getUser(),
                token: getToken(),
                expense_type: data.expense_id,
                supplier_id: data.supplier_id,
                date_from: data.date_from ? Moment(data.date_from).format("YYYY-MM-DD") : "",
                date_to: data.date_to ? Moment(data.date_to).format("YYYY-MM-DD") : "",
                payable: data.payable,
                paid: data.paid,
            }
        );
        return { data: response.data };
    } catch (error) {
        return { error: error };
    }
};

export const searchExpenseByType = async (data, type) => {
    try {
        const response = await getAPICall(
            process.env.REACT_APP_LINK + "reports/get_expense_by_type",
            {
                requester: getUser(),
                token: getToken(),
                date_from: data.date_from ? Moment(data.date_from).format("YYYY-MM-DD") : Moment().format("YYYY-MM-DD"),
                date_to: data.date_to ? Moment(data.date_to).format("YYYY-MM-DD") : Moment().format("YYYY-MM-DD"),
                expense_type: type
            }
        );
        return { data: response.data };
    } catch (error) {
        return { error: error };
    }
};

export const searchExpenseByDate = async (data) => {
    try {
        const response = await getAPICall(
            process.env.REACT_APP_LINK + "reports/get_expense_by_date",
            {
                requester: getUser(),
                token: getToken(),
                date_from: data.date_from ? Moment(data.date_from).format("YYYY-MM-DD") : Moment().format("YYYY-MM-DD"),
                date_to: data.date_to ? Moment(data.date_to).format("YYYY-MM-DD") : Moment().format("YYYY-MM-DD"),
            }
        );
        return { data: response.data };
    } catch (error) {
        return { error: error };
    }
};

export const getExpenseByDateReport = async (filter) => {
    try {
        const response = await getAPICall(
            process.env.REACT_APP_LINK + "reports/get_expense_breakdown_by_day",
            {
                requester: getUser(),
                token: getToken(),
                expense_type_per_day_date_from: Moment(filter.expense_type_per_day_date_from).format('YYYY-MM-DD'),
                expense_type_per_day_date_to: Moment(filter.expense_type_per_day_date_from).format('YYYY-MM-DD'),
                expense_type_per_day_type: filter.expense_type_per_day_type,
            }
        );
        return { data: response.data };
    } catch (error) {
        return { error: error };
    }
}


export const searchExpenseReport = async (data) => {
    try {
        const response = await getAPICall(
            process.env.REACT_APP_LINK + "reports/get_expense",
            {
                requester: getUser(),
                token: getToken(),
                expense_type: data.expense_type,
                particular_id: data.particular_id,
                payment_status: data.payment_status,
                date_from: data.date_from ? Moment(data.date_from).format("YYYY-MM-DD") : Moment().format("YYYY-MM-DD"),
                date_to: data.date_to ? Moment(data.date_to).format("YYYY-MM-DD") : Moment().format("YYYY-MM-DD"),
            }
        );
        return { data: response.data };
    } catch (error) {
        return { error: error };
    }
};


