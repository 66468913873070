import React, { useState, useEffect } from "react";
import { Col, Form, Row, Modal} from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import toast from "react-hot-toast";
import Navbar from "../../Components/Navbar/Navbar";
import Table from "../../Components/TableTemplate/DataTable";
import DeleteModal from "../../Components/Modals/DeleteModal";
import Select from "react-select";
import {
  toastStyle,
  TokenExpiry,
  getType,
  selectDropdownStyle
} from "../../Helpers/Utils/Common";
import { searchProject } from './../../Helpers/apiCalls/Manage/Projects';
import { getAllCustomer } from './../../Helpers/apiCalls/Manage/CustomerAPI';
import { getDistributor, deleteDistributor } from "../../Helpers/apiCalls/Distributor/DistributorApi";
import ReactDatePicker from "react-datepicker";


export default function Distributor() {
  const navigate = useNavigate();
  const userType = getType();
  const [inactive, setInactive] = useState(true);
  const [distributors, setProjects] = useState([]);
  const [tableData, setTableData] = useState([]);
  const [customers, setCustomers] = useState([]);
  const [showLoader, setShowLoader] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [distributorId, setDistributorId] = useState("");
  const [selectBillingDate, setSelectBillingDate] = useState(false);
  const [billingDate, setBillingDate] = useState(new Date())
  const [selectedRow, setSelectedRow] = useState({})
  /* FILTER CONFIGS */
  const [filterConfig, setFilterConfig] = useState({
    distributor_id: "",
    billing_date: ""
  });

  function handleFilterChange(e) {
    const { name, value } = e.target;
    setFilterConfig((prev) => {
      return { ...prev, [name]: value };
    });
  }

  async function fetchCustomers() {
    setShowLoader(true);
    setCustomers([])

    const response = await getAllCustomer(filterConfig);
    if (response.error) {
      if(response.error.data.status !== 404) {
        TokenExpiry(response.error);
      }
    } else {
      var distributors = response.data.data.data.map((data) => {
        var info = {};
        info.label = data.name;
        info.value = data.id;
        return info;
      });
      setCustomers([{label: "All Customers", value: ""}, ...distributors]);
    }
    setShowLoader(false);
  }

  async function fetchProjects() {
    setShowLoader(true);
    setProjects([])

    const response = await searchProject(filterConfig);
    if (response.error) {
      if(response.error.data.status !== 404) {
        TokenExpiry(response.error);
      }
    } else {
      var distributors = response.data.data.map((data) => {
        var info = {};
        info.label = data.name;
        info.value = data.id;
        return info;
      });
      setProjects([{label: "All Projects", value: ""}, ...distributors]);      
    }

    setShowLoader(false);
  }

  async function fetchData() {
    setShowLoader(true);
    setTableData([])
    const response = await getDistributor('');
    if (response.data) {
      setTableData(response.data.data);
    }
    setShowLoader(false);
  }

  async function handleDelete() {
    const response = await deleteDistributor(distributorId);
    if (response.data) {
      toast.success(response.data.response, {style: toastStyle()})
      setShowDeleteModal(false);
      fetchData();
    } else {
      toast.error(response.error.data.messages.response, {style: toastStyle()})
    }
  }

  function handleSelectChange(e, row) {
    setDistributorId(row.id);
    setSelectedRow(row);
    if (e.target.value === "edit") {
      navigate("/distributors/form", {state: {formPurpose:"EDIT", passedId: row.id, distributorDetails: row}});
    } else if (e.target.value === "generate-billing") {
      setSelectBillingDate(true)
    } else {
      setShowDeleteModal(true);
    }
  }

  function ActionBtn(row) {
    return (
      <Form.Select
        value={""}
        name="action"
        className="PO-select-action"
        onChange={(e) =>
          handleSelectChange(e, row)
        }
      >
        <option value="" hidden>
          Select
        </option>
        {userType === "admin" ? (
          <option value="edit" className="color-options">
            Edit
          </option>
        ) : null}

        <option value="generate-billing" className="color-options">Generate Billing</option>
       
        {userType === "admin" && (
          <option value="delete" className="color-red">
            Delete
          </option>
        )}
      </Form.Select>
    );
  }

  function ViewBtn(row) {
    return (
      <button
        type="button"
        className="btn btn-sm view-btn-table"
        onClick={(e) => navigate("print/" + row.id, {state: {distributorDetails: row}})}
      >
        View
      </button>
    );
  }

  useEffect(() => {
    fetchProjects();
    fetchCustomers()
  }, []);

  useEffect(() => {
    fetchData();
  }, [filterConfig]);

  return (
    <div>
      <div className="page">
        <Navbar
          onCollapse={(inactive) => {
            setInactive(inactive);
          }}
          active={"SALES"}
        />
      </div>

      <div className={`manager-container ${inactive ? "inactive" : "active"}`}>
        {/* headers */}
        <Row className="mb-4">
          <Col xs={6}>
            <h1 className="page-title">DISTRIBUTOR REGISTER</h1>
          </Col>
          <Col xs={6} className="d-flex justify-content-end">
            <input
              type="search"
              name="name"
              placeholder="Search distributor..."
              value={filterConfig.name}
              onChange={(e) => handleFilterChange(e)}
              className="search-bar"
            />
            <button
              className="add-btn"
              onClick={() => navigate("/distributors/form", {state: {formPurpose: "ADD"}})}
            >
              Add
            </button>
          </Col>
        </Row>

        <div className="tab-content">
          <div className="my-2 ms-2 PO-filters PI-filters d-flex">
            <span className="me-3 align-middle mt-2">FILTER BY:</span>

            <Select
              className="dropsearch-filter px-0 py-0 me-2"
              classNamePrefix="react-select"
              placeholder="All Projects"
              styles={selectDropdownStyle}
              // value={branch}
              options={distributors}
              onChange={(e) => { setFilterConfig((prev) => {
                  return { ...prev, "distributor_id": e.value };
              });}}
            />

            <Select
              className="dropsearch-filter px-0 py-0 me-2"
              classNamePrefix="react-select"
              placeholder="Select Customer"
              styles={selectDropdownStyle}
              options={customers}
              onChange={(e) => { setFilterConfig((prev) => {
                  return { ...prev, "customer_id": e.value };
              });}}s
            />
            
          </div>

          <Table
            tableHeaders={[
              "-",
              "DISTRIBUTOR NAME",
              "BILLING SCHEDULE",
              "ACTIONS",
            ]}
            headerSelector={[
              "-",
              "name",
              "billing_schedule",
            ]}
            tableData={tableData}
            ViewBtn={(row) => ViewBtn(row)}
            ActionBtn={(row) => ActionBtn(row)}
            showLoader={showLoader}
            withActionData={false}
          />
        </div>
        <div className="mb-2" />
      </div>
      <DeleteModal
        show={showDeleteModal}
        onHide={() => setShowDeleteModal(false)}
        text="distributor"
        onDelete={handleDelete}
      />

      <Modal size="md" show={selectBillingDate} onHide={() => setSelectBillingDate(false)}>
        <Modal.Body className="p-5">
            <Row>
              <span className="edit-label">Select Billing Date</span>
              <ReactDatePicker className="form-control" selected={billingDate} onChange={(value) => setBillingDate(value)}/>
            </Row>
            <Row className="d-flex justify-content-end">
            <div className="col-sm-12 mt-4 d-flex justify-content-end">
                <button className="button-secondary me-3" onClick={() => setSelectBillingDate(false)}>
                  Cancel
                </button>
                <button disabled={billingDate===""} 
                  className={`button-primary ${billingDate ? '' : 'disabled'}`}
                  onClick={() => navigate("/distributors/billing_statement", {state: {formOrigin: 'distributor', billing_id: '', distributor_id: selectedRow.id, distributorDetails: selectedRow, billingDate: billingDate}})}
                 > Generate
                </button>
            </div>
            </Row>
        </Modal.Body>
      </Modal>
    
    </div>
  );
}
