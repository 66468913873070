import React, { useState, useEffect, Fragment } from "react";
import { Row, Col } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import SalesImg from "../../Assets/Images/Dashboard/sales.png";
import ExpensesImg from "../../Assets/Images/Dashboard/expenses.png";
import ReceivablesImg from "../../Assets/Images/Dashboard/receivables.png";
import NetsalesImg from "../../Assets/Images/Dashboard/netsales.png";
import moment from "moment";
import Navbar from "../../Components/Navbar/Navbar";

import "../../Components/Navbar/Navbar.css";
import "./Dashboard.css";
import {  
  formatDateNoTime,
  getName,
  getTime,
  getType,
  numberFormat,
  TokenExpiry,
} from "../../Helpers/Utils/Common";
import { getDashboardReport } from "../../Helpers/apiCalls/DashboardReport/DashboardReportApi";

export default function Dashboard3() {
  let navigate = useNavigate();
  const type = getType();

  const [data, setData] = useState({
    sales: 0,
    expenses: 0,
    net_sales: 0,
    receivables: 0,
    pending_invoice: 0,
    pending_expense: 0,
  });

  const [inactive, setInactive] = useState(false);
  const days = {
    0: "Sunday",
    1: "Monday",
    2: "Tuesday",
    3: "Wednesday",
    4: "Thursday",
    5: "Friday",
    6: "Saturday",
  };

  // Dashboard Data
  async function fetchData() {
    const response = await getDashboardReport();

    if (response.data) {
      setData(response.data.data);
    } else if (response.error) {
      TokenExpiry(response);
    }
  }

  useEffect(() => {
    fetchData();
    // fetchOpenBranches();
  }, []);

  return (
    <div className="dashboard-wrapper justify-content-evenly">
      <div className="page">
        <Navbar
          onCollapse={(inactive) => {
            setInactive(inactive);
          }}
          active={"DASHBOARD"} //Dashboard navbar index
        />
      </div>
      <div className={`dashboard ${inactive ? "inactive" : "active"}`}>
        <div className={"dashboard-wrapper"}>
          <Row className="d-flex justify-content-between align-items-end">
            <Col xs={"auto"}>
              <h2 className="font-medium">
                Hello, <b>{getName()}!</b>👋
              </h2>
            </Col>
            <Col xs={"auto"}>
              <h3 className="date-and-time">{`${getTime(new Date())} ${
                days[new Date().getDay()]
              } | ${formatDateNoTime(new Date())}`}</h3>
            </Col>
          </Row>
          {type === "admin" ? (
            <Fragment>
              <Row className="d-flex">
                <Col className="mt-5">
                  <Row>
                    <Col className="box-1 d-flex justify-content-center align-items-center mx-2 cursor">
                      <div className="me-2 justify-content-center">
                        <img
                          src={SalesImg}
                          className="print-img"
                          style={{ width: "60px", height: "60px" }}
                          alt=""
                        />
                      </div>
                      <Row>
                        <div
                          className="big-hdr"
                          onClick={(e) => navigate("/projectsales")}
                        >
                          Sales
                        </div>
                        <div
                          className="stats"
                          onClick={(e) => navigate("/projectsales")}
                        >
                          PHP{numberFormat(data?.sales ?? 0)}
                        </div>
                        <div className="box-low-text">for the month</div>
                      </Row>
                    </Col>
                    <Col className="box-1 d-flex justify-content-center align-items-center mx-2 cursor">
                      <div className="me-2">
                        <img
                          src={ExpensesImg}
                          className="print-img"
                          style={{ width: "60px", height: "60px" }}
                          alt=""
                        />
                      </div>
                      <Row className="justify-content">
                        <div
                          className="big-hdr"
                          onClick={(e) =>
                            navigate(
                              "/expensereport/" +
                                moment().format("YYYY-MM-DD") +
                                "/" +
                                moment().format("YYYY-MM-DD")
                            )
                          }
                        >
                          Expenses
                        </div>
                        <div
                          className="stats"
                          onClick={(e) =>
                            navigate(
                              "/expensereport/" +
                                moment().format("YYYY-MM-DD") +
                                "/" +
                                moment().format("YYYY-MM-DD")
                            )
                          }
                        >
                          PHP{numberFormat(data.expenses)}
                        </div>
                        <div className="box-low-text">for the month</div>
                      </Row>
                    </Col>
                    <Col className="box-1 d-flex justify-content-center align-items-center mx-2 cursor">
                      <div className="me-2">
                        <img
                          src={ReceivablesImg}
                          className="print-img"
                          style={{ width: "60px", height: "60px" }}
                          alt=""
                        />
                      </div>
                      <Row>
                        <div
                          className="big-hdr"
                          onClick={(e) => navigate("/receivablesagingreport")}
                        >
                          Receivables
                        </div>
                        <div
                          className="stats"
                          onClick={(e) => navigate("/receivablesagingreport")}
                        >
                          PHP{numberFormat(data.receivables)}
                        </div>
                        <div className="box-low-text">for the month</div>
                      </Row>
                    </Col>
                    <Col className="box-1 d-flex justify-content-center align-items-center mx-2">
                      <div className="me-2">
                        <img
                          src={NetsalesImg}
                          className="print-img"
                          style={{ width: "60px", height: "60px" }}
                          alt=""
                        />
                      </div>
                      <Row>
                        <div
                          className="big-hdr"
                          // onClick={(e) => navigate("/netsales")}
                        >
                          Net Sales
                        </div>
                        <div
                          className="stats"
                          // onClick={(e) => navigate("/netsales")}
                        >
                          PHP
                          {numberFormat(data.net_sales ? data.net_sales : 0.0)}
                        </div>
                        <div className="box-low-text">for the month</div>
                      </Row>
                    </Col>
                  </Row>
                </Col>
              </Row>
              <Row className="d-flex">
                <Col className="mt-3">
                  <Row>
                    <Col className="box-1 mx-2">
                      <div className="me-2"></div>
                      <Row>
                        <div
                          className="header cursor"
                          onClick={(e) => navigate("/projectinvoice")}
                        >
                          Pending Invoice
                        </div>
                        <div
                          className="box-text cursor"
                          onClick={(e) => navigate("/projectinvoice")}
                        >
                          {data.pending_invoice}
                        </div>
                        <div className="box-low-text"> Total Number</div>
                      </Row>
                    </Col>
                    <Col className="box-1 mx-2">
                      <div className="me-2"></div>
                      <Row>
                        <div
                          className="header text-left cursor"
                          onClick={(e) =>
                            navigate("/projectinvoice", {
                              state: { name: "sent" },
                            })
                          }
                        >
                          Open Billing
                        </div>
                        <div
                          className="box-text text-center cursor"
                          onClick={(e) =>
                            navigate("/projectinvoice", {
                              state: { name: "sent" },
                            })
                          }
                        >
                          0{data.open_billing}
                        </div>
                        <div className="box-low-text text-end">
                          {" "}
                          Total Number
                        </div>
                      </Row>
                    </Col>
                    <Col className="box-1 mx-2">
                      <div className="me-2"></div>
                      <Row>
                        <div
                          className="header cursor"
                          onClick={(e) => navigate("/se/purchaseinvoices")}
                        >
                          Open Supplies Expense
                        </div>
                        <div
                          className="box-text cursor"
                          onClick={(e) => navigate("/se/purchaseinvoices")}
                        >
                          0{data.open_suppliesexpense}
                        </div>
                        <div className="box-low-text"> Total Number</div>
                      </Row>
                    </Col>
                  </Row>
                </Col>
              </Row>
              <Row className="d-flex">
                <Col className="mt-3">
                  <Row>
                    <Col className="box-1 mx-2">
                      <div className="me-2"></div>
                      <Row>
                        <div
                          className="header cursor"
                          onClick={(e) => navigate("/projectexpense")}
                        >
                          Pending Project Expense
                        </div>
                        <div
                          className="box-text cursor"
                          onClick={(e) => navigate("/projectexpense")}
                        >
                          {data.pending_expense}
                        </div>
                        <div className="box-low-text"> Total Number</div>
                      </Row>
                    </Col>
                    <Col className="box-1 mx-2">
                      <div className="me-2"></div>
                      <Row>
                        <div
                          className="header cursor"
                          onClick={(e) => navigate("/pettycash")}
                        >
                          Petty Cash
                        </div>
                        <div
                          className="box-text cursor"
                          onClick={(e) => navigate("/pettycash")}
                        >
                          0{data.petty_cash}
                        </div>
                        <div className="box-low-text"> Total Number</div>
                      </Row>
                    </Col>
                    <Col className="box-1 mx-2">
                      <div className="me-2"></div>
                      <Row>
                        <div
                          className="header cursor"
                          onClick={(e) => navigate("/suppliesexpenses")}
                        >
                          Pending POs
                        </div>
                        <div
                          className="box-text cursor"
                          onClick={(e) => navigate("/suppliesexpenses")}
                        >
                          0
                        </div>
                        <div className="box-low-text"> Total Number</div>
                      </Row>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Fragment>
          ) : (
            /* Content for non-admin users */
            <div>Content for non-admin users</div>
          )}
        </div>
      </div>
    </div>
  );
}
