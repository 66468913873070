import {
  getToken,
  getUser,
} from "../../Utils/Common";
import Moment from "moment";
import { getAPICall } from "../axiosMethodCalls";

export const searchReceivablesAgingReport = async (data) => {
  try {
    const response = await getAPICall(
      process.env.REACT_APP_LINK + "reports/get_receivables_aging",
      {
        requester: getUser(),
        token: getToken(),
        customer_id: data.customer_id,
        project_id: data.project_id,
        date_from: data.date_from
          ? Moment(data.date_from).format("YYYY-MM-DD")
          : "",
        date_to: data.date_to ? Moment(data.date_to).format("YYYY-MM-DD") : "",
        payable: data.payable,
        paid: data.paid,
      }
    );
    return { data: response.data };
  } catch (error) {
    return { error: error };
  }
};

export const getFinancialReport = async (payload) => {
  try {
    const response = await getAPICall(
      process.env.REACT_APP_LINK + "reports/get_financial_report",
      {
        requester: getUser(),
        token: getToken(),
        ...payload,
      }
    );
    return { data: response.data };
  } catch (error) {
    return { error: error };
  }
};

export const getProjectSalesReport = async (payload) => {
  try {
    const response = await getAPICall(
      // process.env.REACT_APP_LINK + "reports/get_project_sales",
      process.env.REACT_APP_LINK + "project_invoice_payments/search",
      {
        requester: getUser(),
        token: getToken(),
        ...payload,
      }
    );
    return { data: response.data };
  } catch (error) {
    return { error: error };
  }
};
