import React, { forwardRef, useState, useEffect } from "react";
import { Col, Form, Row, Modal, Tabs, Tab } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import Navbar from "../../Components/Navbar/Navbar";
import Table from "../../Components/TableTemplate/DataTable";
import DeleteModal from "../../Components/Modals/DeleteModal";
import ReactDatePicker from "react-datepicker";
import Select from "react-select";
import moment from "moment";
import {
  getTodayDate,
  formatAmount, numberFormat,numberFormatInt,
  getType, toastStyle, selectDropdownStyle
} from "../../Helpers/Utils/Common";
import { toast } from "react-hot-toast";
import { deleteDistributorBilling, getDistributor, getDistributorBilling } from "../../Helpers/apiCalls/Distributor/DistributorApi";
import AddBillingPayment from "./AddBillingPayment";

export default function DistributorBillings () {
  let navigate = useNavigate();
  const userType = getType();
  const [inactive, setInactive] = useState(true);
  const [distributors, setDistributors] = useState([]);
  const [tableData, setTableData] = useState([]);
  const [showLoader, setShowLoader] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showSelect, setShowSelect] = useState(false);
  const [selectedDistributor, setSelectedDistributor] = useState("");
  const [billingDate, setBillingDate] = useState(new Date());
  const [selectedRow, setSelectedRow] = useState({});
  const [addBilling, setAddBilling] = useState(false);
  /* FILTER CONFIGS */
  const [filterConfig, setFilterConfig] = useState({status: "open_bill"});

  function handleFilterChange(e) {
    const { name, value } = e.target;
    setFilterConfig((prev) => {
      return { ...prev, [name]: value };
    });
  }

  async function fetchDistributors() {
    const response = await getDistributor('');
    if (response.data) {
      const res = response.data.data.map((row) => {
        var info = row;
        info.label = row.name;
        info.value = row.id;
        return info;
      })
      setDistributors(res)
    }
  }

  async function fetchData() {
    setShowLoader(true);
    setTableData([]);
    const response = await getDistributorBilling(filterConfig)
    if(response.data) {
      const res = response.data.data.map((row) => {
        var info = row;
        info.billing_date = moment(row.billing_date).format("MMM DD, YYYY");
        info.due_date = moment(row.due_date).format("MMM DD, YYYY");
        info.grand_total = formatAmount(Number(row.grand_total));
        info.paid_amount = formatAmount(Number(row.paid_amount));
        var balance = numberFormatInt(row.grand_total)-(numberFormatInt(row.paid_amount)||0);
        info.balance = formatAmount(balance)||0.00;
        const tempProjects = row.distributor_billing_entries.map((entry) => {
          if (entry.project_name) {
            return entry.project_name
          }
        })
        info.projects = tempProjects.join(', ');
        return info;
      })
      setTableData(res);
      // response.data.data.map((row) => {
      //   const res = row.distributor_billing_entries?.map((entry) => {
      //     var info = entry;
      //     info.status = row.status;
      //     info.distributor_name = row.distributor_name
      //     info.billing_date = moment(entry.distributor_billing_entry_date).format("MMM DD, YYYY");
      //     info.due_date = entry.due_date?moment(entry.due_date).format("MMM DD, YYYY"):'';
      //     info.grand_total = formatAmount(Number(entry.amount));
      //     info.paid_amount = formatAmount(Number(entry.paid_amount));
      //     var balance = numberFormatInt(entry.amount)-(numberFormatInt(entry.paid_amount)||0);
      //     info.balance = formatAmount(balance)||0.00;
      //     return info;
      //   })  
      //   setTableData((prev) => ([...prev, ...res]));
      // })
    } else {
      setTableData([])
    }
    setShowLoader(false);
  }

  async function handleDelete() {
    const response = await deleteDistributorBilling(selectedRow.id);
    
    if (response.data) {
      toast.success(response.data.response, {style: toastStyle()})
      setShowDeleteModal(false);
      fetchData();
    } else {
      toast.error(response.error.data.messages.response, {style: toastStyle()})
    }
  }

  function handleSelectChange(e, row) {
    setSelectedRow(row);
    if (e.target.value === "edit") {
      navigate(
        "/distributors/billing_statement", {state: {formPurpose: "edit", formOrigin: "billing", billing_id: row.id, distributor_id: ''}}
      )
    } else if (e.target.value==="add-payment") {
      setAddBilling(true);
    } else if (e.target.value==="delete") {
     setShowDeleteModal(true)
    } else if (e.target.value ==="view") {
      navigate("view/"+row.id)
    }
  }

  function ActionBtn(row) {
    return (
      <Form.Select
        name="action"
        className="PO-select-action"
        onChange={(e) =>
          handleSelectChange(e, row)
        }
        value={""}
      >
        <option value="" hidden selected>
          Select
        </option>
        {
          userType==="admin"&&row.status==="open_bill" && (
            <>
              <option value="edit" className="color-options">
                Edit
              </option>
              <option value="add-payment" className="color-options">
                Add Payment
              </option>
              <option value="view">View</option>
              <option value="delete" className="color-red">
                Delete
              </option>
            </>
          )
        }
      </Form.Select>
    );
  }

  // function ViewBtn(row) {
  //   return (
  //     <button
  //       type="button"
  //       className="btn btn-sm view-btn-table"
  //       onClick={() => {
  //         // 
  //         navigate("view/"+row.id)
  //       }}
  //     >
  //       View
  //     </button>
  //   );
  // }

  useEffect(() => {
    fetchDistributors()
  }, []);

  useEffect(() => {
    
    fetchData();
  }, [filterConfig]);

  return (
    <div>
      <div className="page">
        <Navbar
          onCollapse={(inactive) => {
            setInactive(inactive);
          }}
          active={"SALES"}
        />
      </div>

      <div className={`manager-container ${inactive ? "inactive" : "active"}`}>
        {/* headers */}
        <Row className="mb-4">
          <Col xs={6}>
            <h1 className="page-title">DISTRIBUTOR BILLINGS REGISTER</h1>
          </Col>
          <Col xs={6} className="d-flex justify-content-end">
            <input
              type="search"
              name="distributor_name"
              placeholder="Search distributor..."
              value={filterConfig.distributor_name}
              onChange={(e) => handleFilterChange(e)}
              className="search-bar"
            />
            <button
              className="add-btn"
              onClick={() => setShowSelect(true)}
            >
              Add
            </button>
          </Col>
        </Row>

        <Tabs activeKey={filterConfig.status} onSelect={(tab) => setFilterConfig((prev) => ({...prev, status: tab}))}>
            <Tab eventKey={"open_bill"} title="Open Bills">
            <div className="tab-content">
              <div className="my-2 ms-2 PO-filters PI-filters d-flex">
                <span className="me-3 align-middle mt-2">FILTER BY:</span>

                <Select
                  className="dropsearch-filter px-0 py-0 me-2"
                  classNamePrefix="react-select"
                  placeholder="All Distributor"
                  styles={selectDropdownStyle}
                  options={distributors}
                  onChange={(e) => { setFilterConfig((prev) => {
                      return { ...prev, "distributor_id": e.value };
                  });}}
                />
                
              </div>
            </div>
              <Table
                tableHeaders={[
                  // "-",
                  "DISTRIBUTOR NAME",
                  "PROJECTS",
                  "TOTAL",
                  // "PAID AMOUNT",
                  "BALANCE",
                  "BILLING DATE",
                  "DEADLINE",
                  "ACTIONS",
                ]}
                headerSelector={[
                  // "-",
                  "distributor_name",
                  "projects",
                  "grand_total",
                  // "paid_amount",
                  "balance",
                  "billing_date",
                  "due_date",
                ]}
                tableData={tableData}
                // ViewBtn={(row) => ViewBtn(row)}
                ActionBtn={(row) => ActionBtn(row)}
                showLoader={showLoader}
                withActionData={false}
              />
            </Tab>
            <Tab eventKey={"closed_bill"} title="Closed Bills">
            <div className="tab-content">
              <div className="my-2 ms-2 PO-filters PI-filters d-flex">
                <span className="me-3 align-middle mt-2">FILTER BY:</span>

                <Select
                  className="dropsearch-filter px-0 py-0 me-2"
                  classNamePrefix="react-select"
                  placeholder="All Distributor"
                  styles={selectDropdownStyle}
                  options={distributors}
                  onChange={(e) => { setFilterConfig((prev) => {
                      return { ...prev, "distributor_id": e.value };
                  });}}
                />
                
              </div>
            </div>
              <Table
                tableHeaders={[
                  // "-",
                  "DISTRIBUTOR NAME",
                  "PROJECTS",
                  "TOTAL",
                  "PAID AMOUNT",
                  "BALANCE",
                  "BILLING DATE",
                ]}
                headerSelector={[
                  // "-",
                  "distributor_name",
                  "projects",
                  "grand_total",
                  "paid_amount",
                  "balance",
                  "billing_date",
                ]}
                tableData={tableData}
                // ViewBtn={(row) => ViewBtn(row)}
                // ActionBtn={(row) => ActionBtn(row)}
                showLoader={showLoader}
                withActionData={false}
              />
            </Tab>
          </Tabs>
        
        <div className="mb-2" />
      </div>

      <DeleteModal
        show={showDeleteModal}
        onHide={() => setShowDeleteModal(false)}
        text="distributor billing"
        onDelete={handleDelete}
      />

      <AddBillingPayment fetchData={fetchData} show={addBilling} hide={()=>{setAddBilling(false)}} distributorBillingData={selectedRow}/>

      <Modal size="md" show={showSelect} onHide={() => setShowSelect(false)}>
        <Modal.Body className="p-5">
            <Row>
            <span className="edit-label">Select Distributor</span>
              <Form.Select onChange={(e) => {setSelectedDistributor(e.target.value)}} className="w-90 ms-2">
                <option value={""}>---</option>
                {
                  distributors.map((row) => (
                    <option value={row.id}>{row.name}</option>
                  ))
                }
              </Form.Select>
            </Row>
            <Row className="mt-3">
            <span className="edit-label">Select Billing Date</span>
              <ReactDatePicker className="form-control" selected={billingDate} onChange={(value) => setBillingDate(value)}/>
            </Row>
              <div className="col-sm-12 mt-3 d-flex justify-content-end">
                <button className="button-secondary me-3" onClick={() => setShowSelect(false)}>
                  Cancel
                </button>
                <button disabled={selectedDistributor===""} 
                  className={`button-primary ${selectedDistributor&&billingDate ? '' : 'disabled'}`}
                  onClick={() => {navigate("/distributors/billing_statement", {state: {formOrigin: 'distributor', billing_id: '', distributor_id: selectedDistributor, billingDate: billingDate}});}}>
                  Generate
                </button>
            </div>
        </Modal.Body>
      </Modal>
    
    </div>
  );
}
